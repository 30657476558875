import { post, get } from "./xhr";

export const loopService = {
    getSavedLoop,
    savedLoop,
    deleteSavedLoop
};

function getSavedLoop(url, headers) {
  return get(url, headers);
}

function savedLoop(url, params, headers) {
  return post(url, params, headers);
}

function deleteSavedLoop(url, headers) {
  return get(url, headers);
}
