import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoginSocialGoogle } from "reactjs-social-login";
import { GoogleLoginButton } from "react-social-login-buttons";
import { Link } from "react-router-dom";

import { FormInput } from "../../../../Utility/FormInput";
import { loginSchema } from "../../../../ValidationSchema/LoginSchema";
import { auth } from "../../../../Action/auth.actions";
import { authConstants } from "../../../../Constants/Auth";
import Button from "../../../../Utility/Button";
import '../../../../components/Common/CSS/common.css'

import { authService } from "../../../../Adapters/auth.services";

import { Modal, Button as ReactBtn } from 'react-bootstrap';
import CustomSuccessToast from "../../../../components/CustomToaster/CustomSuccessToaster";
import { toast } from "react-toastify";
import useWindowDimensions from "../../../../Helpers/useWindowDimensions";
import Otpinput from "../../../Authentication/WhatsappLogin/OtpInput";


const schema = loginSchema;

const WhatsAppOtpModal = ({ handleCloseWhatsappOtpModal, showWhatsAppOtpModal, setShowCreateAccountModal}) => {

    const { windowWidth } = useWindowDimensions();
    const { id } = useParams()
    const pathname = useLocation()
    const navigate = useNavigate()
    const authState = useSelector((state) => state.auth);
  

    const phoneNumber = localStorage.getItem('whatsappNo')
    const code = localStorage.getItem('phonecode') 

 




    return (
        <>
            <Modal
                show={showWhatsAppOtpModal}
                onHide={handleCloseWhatsappOtpModal}
                centered
                size="md"
                animation={false}
                className="modal signup-modal fans-registermodal whatsapp-login-page whatsappModal "
            >
                <Modal.Body>
                    <div className="d-flex align-items-center justify-content-between">
                        <ReactBtn
                            variant="link"
                            onClick={handleCloseWhatsappOtpModal}
                            className="close"
                            style={{ background: 'none', border: 'none', textAlign: 'end' }}
                        >
                            <img src="/assets/img/cross.svg" alt="Close" />
                        </ReactBtn>
                    </div>
                        <div className="verify-otp-whatsapp-modal">
                                    <div className='heading'>
                                        <img src='/assets/img/Login/whatsapp.svg' alt='' />
                                        <h2>OTP has been sent on <br /> WhatsApp number</h2>
                                        <p>+{code}{phoneNumber}</p>
                                    </div>
                                    <div className="form-section"  >
                                        <Otpinput phoneCode={code} phone={phoneNumber}  isModal={true}  handleCloseWhatsappOtpModal={handleCloseWhatsappOtpModal} setShowCreateAccountModal={setShowCreateAccountModal} />

                                        <div className="or">Or</div>

                                        <div className="login-if-account">
                                            Trouble with? <Link>Try another method</Link>
                                        </div>

                                    </div>
                                </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default WhatsAppOtpModal;
