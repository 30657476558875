import { lessonConstants } from "../Constants/LessonSongs";

const initialState = {
    loading: false,
    error: {},
    courses: [],
    playList: [],
    activeTopMenu: {},
    active: {},
    category: {},
    parentCategory: [],
    subCategory: [],
    mainCategoryData: [],
    mainActive: {},
    topicData: [],
    mainLoading: false,
    topLoading: false,
    topicLoading: false,
    coursesPagination: {},
    topPick: {},
    lessonHistoryData: [],
    mainTabs: [],
    knowMoreData: [],
    knowMoreLoading: false,
    filters: {},
    courseLoadMoreData: [],
    startCourse: {},
    mobileLoading: false,
    activeId: {},
    knowLoading: false,
    vedioLoader: false,
    nextLevelId: {},
    levels: [],
    playListLoading: false,
    courseTopicLoadMoreData: [],
    songLessonsLoadMoreData: [],
    recommendedCoursesData: [],
    lessonLoading: false,
    lessonHierarcyData: [],
    mainInstruments: [],
    course_type: 'lessons',
    watchedFreeVideo:{},
    courseCompletionProgress:{},
    songsCountData:{},
    popValue:{},
    assessmentProgress:{},
    clickedValue:false,
    similarCourses:[],
    songUserCount:{},
    logoutState:false,
    authValue:'',
    searchValue:[],
    recentSearchValue:[],
    savedSearchValue:{},
    otherSearchValue:{},
    recentLoading:false,
    relatedLoading:false,
    otherSearchLoading: false,
    content:'',
    loadMoreSearchData:[],
    searchPagination:0,
    recentWatchedChapter:[],
    modalLessonLoading:false,
    detailStateValue: false,
    levelType:{},
    startCourseExtras:{},
    knowMoreDataExtras:[],
    lessonExtras:{},
    courseLoadMoreDataExtras:[],
    mainCategoryDataExtras:[]
}

let index, mainTabs = [];
let loader = {};

export const lessonSongsReducer = (state = initialState, action) => {
    switch (action.type) {
        case lessonConstants.GET_LESSONSONGS_PENDING:
            return {
                ...state,
                loading: true,
                subCategory: [],
                courses: []
            };
            break;

        case lessonConstants.GET_LESSONSONGS_SUCCESS:
            return {
                ...state,
                loading: false,
                courses: action.payload,
                filters: action.payload,
                // activeCategory: action.payload?.lessons[0].child,
                subCategory: []
            };
            break;
        case lessonConstants.GET_LESSONSONGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                subCategory: []
            };
            break;

        case 'SET_COURSE_MENU':
            return {
                ...state,
                active: action.payload,
                error: { "type": "active", "message": action?.payload?.message },
                activeTopMenu: action.payload,
                courseLoadMoreData: [],
                courseLoadMoreDataExtras:[],
            };
            break;

        case 'SET_MAIN_ACTIVE_MENU':
            return {
                ...state,
                mainActive: action.payload,
                error: { "type": "active", "message": action?.payload?.message },
                courseLoadMoreData: [],
                courseLoadMoreDataExtras:[],

            };
            break;

        case 'SET_ACTIVE_MENU':
            return {
                ...state,
                activeMenu: action.payload?.course,
                mainTabs: action?.payload?.course?.lessons,
                courseLoadMoreData: [],
                courseLoadMoreDataExtras:[],
                course_type: action.payload?.course_type ?? state.course_type
            };
            break;

        case 'UPDATE_COURSE_LOADMORE_DATA':
            return {
                ...state,
                courseLoadMoreData: [],
                courseLoadMoreDataExtras:[],
            };
            break;

        case 'SET_LESSON_LOADING':
            return {
                ...state,
                lessonLoading: false,
            };
            break;


        case lessonConstants.GET_LESSONHISTORY_PENDING:
            return {
                ...state,
                loading: true,
            };
            break;

        case lessonConstants.GET_LESSONHISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                lessonHistoryData: action.payload,
            };
            break;

        case lessonConstants.GET_LESSONHISTORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
            break;

        case lessonConstants.GET_PLAYLIST_PENDING:
            return {
                ...state,
                playListLoading: true,
                playList: []
            };
            break;

        case lessonConstants.GET_PLAYLIST_SUCCESS:
            let updatedCourseTopicMoreData = [...state.courseTopicLoadMoreData];
            let updatedSongLessonsMoreData = [...state.songLessonsLoadMoreData];

            const newSongs = action.payload.SongLessons?.data.filter(song => {
                return !updatedSongLessonsMoreData.some(existingSong => existingSong.id === song.id);
            });
            const newCourse = action.payload.CourseTopics?.data.filter(song => {
                return !updatedCourseTopicMoreData.some(existingSong => existingSong.id === song.id);
            });

            updatedCourseTopicMoreData = updatedCourseTopicMoreData.concat(newCourse);
            updatedSongLessonsMoreData = updatedSongLessonsMoreData.concat(newSongs);
            

            return {
                ...state,
                playListLoading: false,
                playList: action.payload,
                courseTopicLoadMoreData: updatedCourseTopicMoreData,
                songLessonsLoadMoreData: updatedSongLessonsMoreData

            };
            break;

        case lessonConstants.GET_PLAYLIST_FAILURE:
            return {
                ...state,
                playListLoading: false,
                error: action.error,
                playList: []
            };
            break;

        case 'UPDATE_SAVE_COURSE_LOADMORE_DATA':
            return {
                ...state,
                songLessonsLoadMoreData: [],
                courseTopicLoadMoreData: []
            };
            break;

        case lessonConstants.SAVE_PLAYLIST_PENDING:
            return {
                ...state,
                submitted: true,
            };
            break;

        case lessonConstants.SAVE_PLAYLIST_SUCCESS:
            return {
                auth: false,
                userDetails: action.payload,
                submitted: false,
                error: {}
            };
            break;

        case lessonConstants.SAVE_PLAYLIST_FAILURE:
            return {
                ...state,
                submitted: false,
                error: { "type": "playlist", "message": action.payload?.message }
            };
            break;

        case lessonConstants.GET_LESSON_CATEGORY_PENDING:
            return {
                ...state,
                loading: true,
                parentCategory: [],
                subCategory: []
            };
            break;

        case lessonConstants.GET_LESSON_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                category: action.payload,
                parentCategory: action.payload?.data ?? [],
                subCategory: [],
                activeTopMenu: action.payload?.data[0]
            };

        case lessonConstants.GET_LESSON_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                parentCategory: [],
                subCategory: []
            };
            break;

        case lessonConstants.GET_TOP_CATEGORY_PENDING:
            return {
                ...state,
                topLoading: true,
                subCategory: []
            };
            break;

        case lessonConstants.GET_TOP_CATEGORY_SUCCESS:
            return {
                ...state,
                topLoading: false,
                subcategory: action.payload,
            };

        case lessonConstants.GET_TOP_CATEGORY_FAILURE:
            return {
                ...state,
                topLoading: false,
                error: action.error,
                subcategory: []
            };
            break;

        case lessonConstants.GET_MAIN_CATEGORY_PENDING:
            return {
                ...state,
                mainLoading: true
            };
            break;

        case lessonConstants.GET_MAIN_CATEGORY_SUCCESS:

            let updatedMoreData = [...state.courseLoadMoreData];
            
            index = state.mainTabs.findIndex(lesson => lesson?.id == state?.mainActive);


            if(index == -1){
                return
            }
            mainTabs = state.mainTabs;
            mainTabs[index].total = action?.payload?.total;
            

            // Filerting data to avoid duplicacy entry...

            const newData = action.payload?.mainCategory.filter(categ => {
                return !updatedMoreData.some(existingCateg => existingCateg.id === categ.id);
            });

            // Append new songs to loadMoreData
            updatedMoreData = updatedMoreData.concat(newData);

            return {
                ...state,
                mainLoading: false,
                mainCategoryData: action.payload,
                coursesPagination: action.payload?.pagination,
                mainTabs: mainTabs,
                category: action.payload,
                courseLoadMoreData: updatedMoreData
            };

        case lessonConstants.GET_MAIN_CATEGORY_FAILURE:
            return {
                ...state,
                mainLoading: false,
                error: action.error,
                mainCategoryData: [],
                mainTabs: [],
            };
            break;

        case 'START_COURSE_INIT_STATE':
            loader = { knowMoreLoading: true }
            return {
                ...state,
                startCourse: {},
            };
            break;

        case 'START_COURSE_PENDING':
            loader = { knowMoreLoading: true }
            return {
                ...state,
                startCourse: {},
                nextLevelId: {},
                knowLoading: true,
                knowMoreData:{},
                ...loader,
               
            };
            break;

        case 'START_COURSE_SUCCESS':
           
            loader = { knowMoreLoading: false }
            let levels = state.levels;
            let levelState=false
            if (action.payload.data.level == 2) {
                levels = action.payload?.data?.lessons;
                levelState=true
               
            }
           
          
            return {
                ...state,
                startCourse: action.payload.data,
                levels: levels,
                knowLoading: false,
                levelState:levelState,
                ...loader,
            };
            break;

        case 'START_COURSE_FAILURE':
            loader = { knowMoreLoading: true }
            return {
                ...state,
                knowLoading: false,
                startCourse: {},
                nextLevelId: {},
                ...loader
            };
            break;

        case 'ACTIVE_PARENT':
            return {
                ...state,
                activeId: action.payload,

            };
            break;

        case 'START_COURSE_DATA_EMPTY':
            return { 
                ...state,
                logoutState:action.payload
              
            };
            break;

        case lessonConstants.GET_TOPIC_PENDING:
            return {
                ...state,
                topicLoading: true,
                subcategory: [],
                topicData: []
            };
            break;

        case lessonConstants.GET_TOPIC_SUCCESS:
            return {
                ...state,
                topicLoading: false,
                topicData: action.payload,
                subcategory: []
            };

        case lessonConstants.GET_TOPIC_FAILURE:
            return {
                ...state,
                topicLoading: false,
                error: action.error,
                subcategory: [],
                topicData: []
            };
            break;

        case lessonConstants.GET_TOP_PICK_PENDING:
            return {
                ...state,
                subCategory: []
            };
            break;

        case lessonConstants.GET_TOP_PICK_SUCCESS:
            return {
                ...state,
                topPick: action.payload,
            };

        case lessonConstants.GET_TOP_PICK_FAILURE:
            return {
                ...state,
                error: action.error,
                subcategory: []
            };
            break;

        case lessonConstants.GET_KNOW_MORE_PENDING:
            return {
                ...state,
                subCategory: [],
                knowLoading: true,
                nextLevelId: {},
                mobileLoading: true,
                knowMoreData: []

            };
            break;

        case lessonConstants.GET_KNOW_MORE_SUCCESS:
            return {
                ...state,
                knowMoreData: action.payload,
                nextLevelId: action.payload,
                levelData: action.payload.lessons,
                knowLoading: false,
                mobileLoading: false
            };

        case lessonConstants.GET_KNOW_MORE_FAILURE:
            return {
                ...state,
                error: action.error,
                nextLevelId: {},
                subcategory: [],
                knowLoading: false,
                mobileLoading: false
            };
            break;

        case lessonConstants.GET_RECOMMENDED_COURSES_PENDING:
            return {
                ...state,
                recommendedLoading: true,
                recommendedCoursesData: []
            };
            break;

        case lessonConstants.GET_RECOMMENDED_COURSES_SUCCESS:
            return {
                ...state,
                recommendedLoading: false,
                recommendedCoursesData: action.payload,
            };
            break;

        case lessonConstants.GET_RECOMMENDED_COURSES_FAILURE:
            return {
                ...state,
                recommendedLoading: false,
                error: action.error,
                recommendedCoursesData: []
            };
            break;

        case 'SET_MODULE_DATA':
            return {
                ...state,
                knowMoreData: action.payload,
                knowMoreDataExtras:action.payload
            };
            break;

        case lessonConstants.GET_LESSONSHIERARCY_PENDING:
            return {
                ...state,
                knowLoading: true,
                modalLessonLoading: true,
                knowMoreLoading: false,
                lessonHierarcyData: [],
                
            };
            break;

        case lessonConstants.GET_LESSONSHIERARCY_SUCCESS:
         
                return {
                    ...state,
                    knowLoading: false,
                    lessonHierarcyData: action.payload,
                    modalLessonLoading: false,
                    subCategory: [],
                    
                
            }
          
            break;
        case lessonConstants.GET_LESSONSHIERARCY_FAILURE:
            return {
                ...state,
                knowLoading: false,
                modalLessonLoading: false,
                error: action.error,
                lessonHierarcyData: []
            };
            break;

        case lessonConstants.GET_LESSONSHIERARCY_EXTRAS_PENDING:
            return {
                ...state,
                lessonHierarcyExtrasData: {}
            };
            break;

        case lessonConstants.GET_LESSONSHIERARCY_EXTRAS_SUCCESS:
            return {
                ...state,
                lessonLoading: false,
                lessonHierarcyExtrasData: action.payload,
                subCategory: []
            };
            break;
        case lessonConstants.GET_LESSONSHIERARCY_EXTRAS_FAILURE:
            return {
                ...state,
                modalLessonLoading: false,
                error: action.error,
                lessonHierarcyExtrasData: []
            };
            break;


        case lessonConstants.GET_RECENT_WATCH_DATA_PENDING:
            return {
                ...state,
                recentWatchedChapter: []
            };
            break;

        case lessonConstants.GET_RECENT_WATCH_DATA_SUCCESS:
            return {
                ...state,
                recentWatchedChapter: action.payload,
            };
            break;
        case lessonConstants.GET_RECENT_WATCH_DATA_FAILURE:
            return {
                ...state,
                error: action.error,
                recentWatchedChapter: []
            };
            break;


        case 'MAIN_INSTRUMENTS_DATA':
            return {
                ...state,
                mainInstruments: action.payload,
            };
            break;

        case 'SET_COURSE_TYPE':
            return {
                ...state,
                course_type: action.payload,
            }
            break;

        case 'SET_VIDEO_PROGRESS':
            return {
                ...state,
                progress: action.payload,
            }

        case lessonConstants.GET_MEMBERSHIP_VIEW_PENDING:
            return {
                ...state,
                watchedFreeVideo: {}
            };
            break;

        case lessonConstants.GET_MEMBERSHIP_VIEW_SUCCESS:
            return {
                ...state,
                watchedFreeVideo: action.payload
            };
            break;

        case lessonConstants.GET_MEMBERSHIP_VIEW_FAILURE:
            return {
                ...state,
                watchedFreeVideo: action.payload
            };
            break;

        case lessonConstants.GET_COURSE_COMPLETION_PROGRESS_PENDING:
            return {
                ...state,
                courseCompletionProgress: {}
            };
            break;

        case lessonConstants.GET_COURSE_COMPLETION_PROGRESS_SUCCESS:
            return {
                ...state,
                courseCompletionProgress: action.payload
            };
            break;

        case lessonConstants.GET_COURSE_COMPLETION_PROGRESS_FAILURE:
            return {
                ...state,
                courseCompletionProgress: {}
            };
            break;

        case lessonConstants.GET_SONGS_COUNT_PENDING:
            return {
                ...state,
                songsCountData: {}
            };
            break;

        case lessonConstants.GET_SONGS_COUNT_SUCCESS:
            return {
                ...state,
                songsCountData: action.payload
            };
            break;

        case lessonConstants.GET_SONGS_COUNT_FAILURE:
            return {
                ...state,
                songsCountData: {}
            };
            break;

        case lessonConstants.SAVE_POP_UP_PENDING:
            return {
                ...state,
                popValue: {}
            };
            break;

        case lessonConstants.SAVE_POP_UP_SUCCESS:
            return {
                ...state,
                popValue: action.payload
            };
            break;

        case lessonConstants.SAVE_POP_UP_FAILURE:
            return {
                ...state,
                popValue: {}
            };
            break;


        case lessonConstants.GET_ASSESSMENT_PROGRESS_PENDING:
            return {
                ...state,
                assessmentProgress: {}
            };
            break;

        case lessonConstants.GET_ASSESSMENT_PROGRESS_SUCCESS:
            return {
                ...state,
                assessmentProgress: action.payload
            };
            break;

        case lessonConstants.GET_ASSESSMENT_PROGRESS_FAILURE:
            return {
                ...state,
                assessmentProgress: {}
            };
            break;

        case 'EMPTY_COMPLETE_COURSE_DATA':
            return {
                ...state,
                courseCompletionProgress: {},
             

            };
            break;

        case 'SET_CLICK_VALUE':

            return {
                ...state,
                clickedValue: action.payload
            };

        case "SET_NEWCOURSEDETAIL_STATE":
            return{
                ...state,
                detailStateValue: action.payload,
            }

        case lessonConstants.GET_SIMILAR_COURSE_PENDING:
            return {
                ...state,
                similarCourses: {}
            };
            break;

        case lessonConstants.GET_SIMILAR_COURSE_SUCCESS:
            return {
                ...state,
                similarCourses: action.payload
            };
            break;

        case lessonConstants.GET_SIMILAR_COURSE_FAILURE:
            return {
                ...state,
                similarCourses: {}
            };
            break;

        case lessonConstants.GET_SONG_COUNT_PENDING:
            return {
                ...state,
                songUserCount: {}
            };
            break;

        case lessonConstants.GET_SONG_COUNT_SUCCESS:
            return {
                ...state,
                songUserCount: action.payload
            };
            break;

        case lessonConstants.GET_SONG_COUNT_FAILURE:
            return {
                ...state,
                songUserCount: {}
            };
            break;

        case 'SET_AUTH_VALUE':
            return {
                ...state,
                authValue:action.payload
            }


        case lessonConstants.GET_SEARCH_PENDING:
            return {
                ...state,
                searchValue: [],
                relatedLoading:true,
            };
            break;

        case lessonConstants.GET_SEARCH_SUCCESS:
            let updatedSongData = [...state.loadMoreSearchData];

            let newSearchData;

            newSearchData = action.payload.searchValue.filter(song => {
                return !updatedSongData.some(existingSong => existingSong.id === song.id);
            });


            updatedSongData = updatedSongData.concat(newSearchData)
            
            
            return {
                ...state,
                searchValue: action.payload.searchValue,
                relatedLoading:false,
                loadMoreSearchData: updatedSongData,
                searchPagination:action.payload.searchPagination
            };
            break;

        case lessonConstants.GET_SEARCH_FAILURE:
            return {
                ...state,
                searchValue: [],
                relatedLoading:false
            };
            break;

        case lessonConstants.GET_RECENT_SEARCH_PENDING:
            return {
                ...state,
                recentSearchValue: [],
                recentLoading:true
            };
            break;

        case lessonConstants.GET_RECENT_SEARCH_SUCCESS:
            return {
                ...state,
                recentSearchValue: action.payload,
                recentLoading:false
            };
            break;

        case lessonConstants.GET_RECENT_SEARCH_FAILURE:
            return {
                ...state,
                recentSearchValue: [],
                recentLoading:false
            };
            break;

        case lessonConstants.GET_SAVED_SEARCH_PENDING:
            return {
                ...state,
                savedSearchValue: {}
            };
            break;

        case lessonConstants.GET_SAVED_SEARCH_SUCCESS:
            return {
                ...state,
                savedSearchValue: action.payload
            };
            break;

        case lessonConstants.GET_SAVED_SEARCH_FAILURE:
            return {
                ...state,
                savedSearchValue: {}
            };
            break;

        case lessonConstants.GET_OTHER_SEARCH_PENDING:
            return {
                ...state,
                otherSearchValue: [],
                otherSearchLoading:true,
            };
            break;

        case lessonConstants.GET_OTHER_SEARCH_SUCCESS:
            return {
                ...state,
                otherSearchValue: action.payload,
                otherSearchLoading:false,
            };
            break;

        case lessonConstants.GET_OTHER_SEARCH_FAILURE:
            return {
                ...state,
                otherSearchValue: [],
                otherSearchLoading:false,
            };
            break;

        case 'SET_INSTRUMENT_PAGE_CONTENT':
            return {
                ...state,
                content:action.payload
            }

        case 'SET_EMPTY_SEARCH_VALUE':
            return {
                ...state,
                content:{},
                otherSearchValue: [],
             
            }
        case 'SET_EMPTY_INSTRUMENT_PAGE_CONTENT':
            return {
                ...state,
                content:{},
               
            }

        case 'SET_RECENT_SEARCH_VALUE':
            return {
                ...state,
                loadMoreSearchData:[],
                recentSearchValue:[]
            }

        case lessonConstants.GET_LEVEL_TYPE_PENDING:
            return {
                ...state,
                levelType: {}
            };
            break;

        case lessonConstants.GET_LEVEL_TYPE_SUCCESS:
           
                return {
                    ...state,
                    lessonLoading: false,
                    levelType: action.payload,
                };
          
            break;
        case lessonConstants.GET_LEVEL_TYPE_FAILURE:
            return {
                ...state,
                error: action.error,
                levelType: []
            };
            break;

            case 'START_COURSE_EXTRAS_PENDING':
                return {
                    ...state,
                    startCourseExtras: {},
                    knowLoading: true,
                    ...loader,
                   
                };
                break;
    
            case 'START_COURSE_EXTRAS_SUCCESS':

                return {
                    ...state,
                    startCourseExtras: action.payload.data,
                    knowLoading: false,
                    ...loader,
                };
                break;
    
            case 'START_COURSE_EXTRAS_FAILURE':
                return {
                    ...state,
                    knowLoading: false,
                    startCourseExtras: {},
                    ...loader
                };
                break;

        case lessonConstants.GET_KNOW_MORE_EXTRAS_PENDING:
            return {
                ...state,
                knowMoreLoading: true,
                nextLevelId: {},
                mobileLoading: true,
                knowMoreDataExtras: []

            };
            break;

        case lessonConstants.GET_KNOW_MORE_EXTRAS_SUCCESS:
            return {
                ...state,
                knowMoreDataExtras: action.payload,
                knowMoreLoading: false,
            };

        case lessonConstants.GET_KNOW_MORE_EXTRAS_FAILURE:
            return {
                ...state,
                error: action.error,
                knowMoreLoading: false,
                mobileLoading: false
            };
            break;

            case lessonConstants.GET_LESSON_EXTRAS_PENDING:
                return {
                    ...state,
                    mainLoadingExtras: true
                };
                break;
    
            case lessonConstants.GET_LESSON_EXTRAS_SUCCESS:
    
                let updatedMoreData1 = [...state.courseLoadMoreDataExtras];
                
                index = state.mainTabs.findIndex(lesson => lesson?.id == state?.mainActive);

                if(index == -1){
                    return
                }
    
                mainTabs = state.mainTabs;
                mainTabs[index].total = action?.payload?.total;
    
                // Filerting data to avoid duplicacy entry...
    
                const newData1 = action.payload?.mainCategoryExtras.filter(categ => {
                    return !updatedMoreData1.some(existingCateg => existingCateg.id === categ.id);
                });

    
                // Append new songs to loadMoreData
                updatedMoreData1 = updatedMoreData1.concat(newData1);
                return {
                    ...state,
                    mainLoadingExtras: false,
                    mainCategoryDataExtras: action.payload,
                    coursesPagination: action.payload?.pagination,
                    mainTabs: mainTabs,
                    category: action.payload,
                    courseLoadMoreDataExtras: updatedMoreData1
                };
    
            case lessonConstants.GET_LESSON_EXTRAS_FAILURE:
                return {
                    ...state,
                    mainLoadingExtras: false,
                    error: action.error,
                    mainCategoryDataExtras: [],
                    mainTabs: [],
                };
                break;




        default:
            return state;
    }
}