export const songsConstants = {
    GET_SONGS_PENDING: "GET_SONGS_PENDING",
    GET_SONGS_SUCCESS: "GET_SONGS_SUCCESS",
    GET_SONGS_FAILURE: "GET_SONGS_FAILURE",

    GET_TABS_SONGS_PENDING: "GET_TABS_SONGS_PENDING",
    GET_TABS_SONGS_SUCCESS:"GET_TABS_SONGS_SUCCESS",
    GET_TABS_SONGS_FAILURE: "GET_TABS_SONGS_FAILURE",

    GET_SONGS_SLUG_PENDING: "GET_SONGS_SLUG_PENDING",
    GET_SONGS_SLUG_SUCCESS: "GET_SONGS_SLUG_SUCCESS",
    GET_SONGS_SLUG_FAILURE: "GET_SONGS_SLUG_FAILURE",

    GET_PLAYLIST_PENDING: "GET_PLAYLIST_PENDING",
    GET_PLAYLIST_SUCCESS: "GET_PLAYLIST_SUCCESS",
    GET_PLAYLIST_FAILURE: "GET_PLAYLIST_FAILURE",

    GET_SEARCH_SONGS_PENDING: "GET_SEARCH_SONGS_PENDING",
    GET_SEARCH_SONGS_SUCCESS: "GET_SEARCH_SONGS_SUCCESS",
    GET_SEARCH_SONGS_FAILURE: "GET_SEARCH_SONGS_FAILURE",

    GET_SONGS_EXTRAS_PENDING:"GET_SONGS_EXTRAS_PENDING",
    GET_SONGS_EXTRAS_SUCCESS:"GET_SONGS_EXTRAS_SUCCESS",
    GET_SONGS_EXTRAS_FAILURE:"GET_SONGS_EXTRAS_FAILURE"

}