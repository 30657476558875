import { post, get } from "./xhr";

export const instructorService = {
  getInstructors,
  getInstructorDetails,
  getInstructorCategories,
  sendTokenInHeader,
  // getUserStreak,
  getLikedData
};

function getInstructors(url) {
    return get(url);
}
  
function getInstructorDetails(url) {
    return get(url);
}

function getInstructorCategories(url, headers) {
  return get(url, headers);
}

function sendTokenInHeader(url, params, headers) {
  return post(url, params, headers);
}

// function getUserStreak(url, headers) {
//   return get(url, headers);
// }

function getLikedData(url, headers) {
  return get(url, headers);
}