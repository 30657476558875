import {seoConstants} from '../Constants/SeoConstants'

const initialState = { 
    seoData:{},
    seoValues:{}
}

export const seoReducers   = ( state=initialState, action ) => {
    switch(action.type) {
        case seoConstants.GET_SEO_PENDING:
            return {
                ...state,
                seoData:{},
            };
            break;

        case seoConstants.GET_SEO_SUCCESS:

            return {
                ...state,
                seoData: action.payload

            };
            break;

        case seoConstants.GET_SEO_FAILURE:
            return {
                ...state,
            };
            break;

        case 'SET_SEO_VALUE':
            return {
                ...state,
                seoValues:action.payload
            }
            break;

        default:
            return state;
        }
    }