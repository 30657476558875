import { songsService } from "../Adapters/songs.services";
import { songsConstants } from "../Constants/SongsConstants";

export const songs = {
    getSongs,
    getSongsTabs,
    getPlaylistSlug,
    getPlaylist,
    getSongsExtras
}
 
async function getSongs(params, filterType = '', headers, free, instrument) {
   
    let parent = '';
    
    if (free) {
        parent = `?filter[path]=${params}&free=1`;
    } else {
        parent = `?filter[path]=${params}`;
    }
    // sorting param
    if (filterType?.sort) {
        parent += `&sort=${filterType?.sort}`;
    }

    if (filterType?.searchValue) {
        parent += `&filter[title]=${filterType?.searchValue}`;
    }
        
    if (filterType?.sort_type) {
        parent += `&sort_type=${filterType?.sort_type}`;
    }

    if (filterType?.genre) {
        parent += `&genre=${filterType?.genre}`;
    }

    if (filterType?.difficulty) {
        parent += `&difficulty=${filterType?.difficulty}`;
    }
    if (filterType?.instructor) {
        parent += `&instructor=${filterType?.instructor}`;
    }
    if (filterType?.language) {
        parent += `&language=${filterType?.language}`;
    }
    if (filterType?.otherFilters) {
        parent += `&filter[${filterType?.otherFilters}]=1`;
    }
    // Pagination 
    if (filterType?.page) {
        parent += `&page=${filterType?.page}`;
    }
    
    return (dispatch) => {
        dispatch(request());
        songsService
        .getSongs(`/api/songs${parent}`, headers)
        .then((response) => {
            const songData = { songs: response.data?.data?.lessons ?? [], total: response.data?.pagination?.total ?? 0, pagination: response.data?.pagination, filters: response?.data, isSearch: filterType?.isSearch ?? '', activeSearch: filterType?.activeSearch ?? ''}
            dispatch(success(songData));
        })
        .catch((error) => {
            dispatch(errorSongs(error));
        });
    };
  
    function errorSongs(error) {
        return { type: songsConstants.GET_SONGS_FAILURE, error: error };
    }
  
    function request() {
        return { type: songsConstants.GET_SONGS_PENDING };
    }
  
    function success(data) {
        return { type: songsConstants.GET_SONGS_SUCCESS, payload: data };
    }
}

async function getSongsExtras(params, filterType = '', headers, free, instrument) {
   
    let parent = '';
    
    if (free) {
        parent = `?filter[path]=${params}&free=1`;
    } else {
        parent = `?filter[path]=${params}`;
    }
    // sorting param
    if (filterType?.sort) {
        parent += `&sort=${filterType?.sort}`;
    }

    if (filterType?.searchValue) {
        parent += `&filter[title]=${filterType?.searchValue}`;
    }
        
    if (filterType?.sort_type) {
        parent += `&sort_type=${filterType?.sort_type}`;
    }

    if (filterType?.genre) {
        parent += `&genre=${filterType?.genre}`;
    }

    if (filterType?.difficulty) {
        parent += `&difficulty=${filterType?.difficulty}`;
    }
    if (filterType?.instructor) {
        parent += `&instructor=${filterType?.instructor}`;
    }
    if (filterType?.language) {
        parent += `&language=${filterType?.language}`;
    }
    if (filterType?.otherFilters) {
        parent += `&filter[${filterType?.otherFilters}]=1`;
    }
    // Pagination 
    if (filterType?.page) {
        parent += `&page=${filterType?.page}`;
    }
    
    return (dispatch) => {
        dispatch(request());
        songsService
        .getSongs(`/api/songs-extras${parent}`, headers)
        .then((response) => {
            const songData = { songsExtras: response.data?.data?.lessons ?? [], total: response.data?.pagination?.total ?? 0, pagination: response.data?.pagination, filters: response?.data, isSearch: filterType?.isSearch ?? '', activeSearch: filterType?.activeSearch ?? ''}
            dispatch(success(songData));
        })
        .catch((error) => {
            dispatch(errorSongs(error));
        });
    };
  
    function errorSongs(error) {
        return { type: songsConstants.GET_SONGS_EXTRAS_FAILURE, error: error };
    }
  
    function request() {
        return { type: songsConstants.GET_SONGS_EXTRAS_PENDING };
    }
  
    function success(data) {
        return { type: songsConstants.GET_SONGS_EXTRAS_SUCCESS, payload: data };
    }
}

async function getSongsTabs(params,headers) {
   
    try {
        const response  =  await songsService
        .getSongs(`/api/songs?parent=${params.parent}&filter[path]=${params.path}`, headers);
        const data = response.data?.data;
        return { type: songsConstants.GET_TABS_SONGS_SUCCESS, payload: data };
    } catch(e) {
        return { type: songsConstants.GET_TABS_SONGS_FAILURE, error: e?.message };
    }
    
}

// async function getSearchData(params,headers) {
    
//     try {
//         const response  =  await songsService
//         .getSongs(`/api/songs?filter[title]=${params}`, headers);
//         const data = response.data?.data;
//         return { type: songsConstants.GET_SEARCH_SONGS_SUCCESS, payload: data };
//     } catch(e) {
//         return { type: songsConstants.GET_SEARCH_SONGS_FAILURE, error: e?.message };
//     }
    
// }

async function getPlaylistSlug(slug, filterType='',headers) {
    let parent = '';
    
    if (slug) {
        parent = `?tag=${slug?.tag ? slug?.tag : slug}`;
    } 
    if (filterType?.searchValue) {
        parent += `&filter[title]=${filterType?.searchValue}`;
    }

    if (filterType) {
        parent += `&instrument=${slug?.instrument ? slug?.instrument : filterType}`;
    }

    if (filterType?.sort_type) {
        parent += `&sort_type=${filterType?.sort_type}`;
    }
    if (filterType?.sort) {
        parent += `&sort=${filterType?.sort}`;
    }
    if (filterType?.genre) {
        parent += `&genre=${filterType?.genre}`;
    }

    if (filterType?.difficulty) {
        parent += `&difficulty=${filterType?.difficulty}`;
    }
    if (filterType?.instructor) {
        parent += `&instructor=${filterType?.instructor}`;
    }
    if (filterType?.language) {
        parent += `&language=${filterType?.language}`;
    }
    if (filterType?.otherFilters) {
        parent += `&filter[${filterType?.otherFilters}]=1`;
    }
    // Pagination 
    if (filterType?.page) {
        parent += `&page=${filterType?.page}`;
    }

    return (dispatch) => {
        dispatch(request());
        songsService
        .getSongs(`/api/songs${parent}`, headers)
        .then((response) => {
            const data = { lessons: response.data?.data?.lessons ?? [], totalPlaylist: response.data?.pagination?.total, totalSongs: response?.data?.data?.songs,isSearch: filterType?.isSearch ?? '', activeSearch: filterType?.activeSearch ?? ''}
            dispatch(success(data));
        })
        .catch((error) => {
            dispatch(errorSongsSlug(error));
        });
    };
  
    function errorSongsSlug(error) {
        return { type: songsConstants.GET_SONGS_SLUG_FAILURE, error: error };
    }
  
    function request() {
        return { type: songsConstants.GET_SONGS_SLUG_PENDING };
    }
  
    function success(data) {
        return { type: songsConstants.GET_SONGS_SLUG_SUCCESS, payload: data };
    }
}

async function getPlaylist(headers, params) {
    let url = `/api/playlist`;
    if(params?.instrument) {
        url = `/api/playlist?instrument=${params.instrument}`
    }
    return (dispatch) => {
        dispatch(request());
        songsService
        .getSongs(url, headers)
        .then((response) => {
            dispatch(success(response.data));
        })
        .catch((error) => {
            dispatch(errorPlaylist(error));
        });
    };
  
    function errorPlaylist(error) {
        return { type: songsConstants.GET_PLAYLIST_FAILURE, error: error };
    }
  
    function request() {
        return { type: songsConstants.GET_PLAYLIST_PENDING };
    }
  
    function success(data) {
        return { type: songsConstants.GET_PLAYLIST_SUCCESS, payload: data };
    }
}
