export const feedbackConstants = {
    GET_FEEDBACK_PENDING: " GET_FEEDBACK_PENDING",
    GET_FEEDBACK_SUCCESS: " GET_FEEDBACK_SUCCESS",
    GET_FEEDBACK_FAILURE: " GET_FEEDBACK_FAILURE",

    UPLOAD_FAILURE:"UPLOAD_FAILURE",
    UPLOAD_SUCCESS: "UPLOAD_SUCCESS",
    UPLOAD_PENDING: "UPLOAD_PENDING",

    GET_SIMILAR_SONGS_PENDING: "GET_SIMILAR_SONGS_PENDING",
    GET_SIMILAR_SONGS_SUCCESS: "GET_SIMILAR_SONGS_SUCCESS",
    GET_SIMILAR_SONGS_FAILURE: "GET_SIMILAR_SONGS_FAILURE",

    DELETE_FEEDBACK_PENDING: "DELETE_FEEDBACK_PENDING",
    DELETE_FEEDBACK_SUCCESS: "DELETE_FEEDBACK_SUCCESS",
    DELETE_FEEDBACK_FAILURE: "DELETE_FEEDBACK_FAILURE",

    UPLOAD_PROGRESS_PENDING: "UPLOAD_PROGRESS_PENDING",
    UPLOAD_PROGRESS_SUCCESS: "UPLOAD_PROGRESS_SUCCESS",
    UPLOAD_PROGRESS_FAILURE: "UPLOAD_PROGRESS_FAILURE"
}
