import { QaConstants } from "../Constants/QaConstants";

const initialState = {
    discussionLoading: false,
    discussionData: [],
    discussionRepliesData: [],
    postDiscussion: [],
    postingDiscussion: false,
    deleteCommentSucess: false,
    pagination: {}

};

export const QaReducer = (state = initialState, action) => {
    switch (action.type) {

        case QaConstants.GET_DISCUSSIONS_PENDING:
            return {
                ...state,
                discussionLoading: true
            };

        case QaConstants.GET_DISCUSSIONS_SUCCESS:
            const existingDiscussionsMap = new Map(state.discussionData.map(d => [d.id, d]));

            action.payload.data.forEach(discussion => {
                const existingDiscussion = existingDiscussionsMap.get(discussion.id);

                if (existingDiscussion) {
                    const existingRepliesMap = new Map(existingDiscussion.replies.map(r => [r.id, r]));
                    const updatedReplies = discussion.replies.map(reply => {
                        const existingReply = existingRepliesMap.get(reply.id);
                        return existingReply ? { ...existingReply, ...reply } : reply;
                    });

                    existingDiscussionsMap.set(discussion.id, {
                        ...existingDiscussion,
                        ...discussion,
                        replies: updatedReplies,
                        currentPage: action.payload.pagination.current_page
                    });
                } else {
                    existingDiscussionsMap.set(discussion.id, {
                        ...discussion,
                        currentPage: action.payload.pagination.current_page
                    });
                }
            });

            const updatedDiscussions = Array.from(existingDiscussionsMap.values());
            updatedDiscussions.sort((a, b) => new Date(b.created) - new Date(a.created));

            return {
                ...state,
                discussionLoading: false,
                discussionData: updatedDiscussions,
                pagination: action.payload.pagination
            };


        case QaConstants.GET_DISCUSSIONS_FAILURE:
            return {
                ...state,
                discussionLoading: false,
                error: action.error,
                discussionData: [],
                pagination: {}
            };

        case QaConstants.POST_DISCUSSION_PENDING:
            return {
                ...state,
                postingDiscussion: true,
            };

        case QaConstants.POST_DISCUSSION_SUCCESS:
            return {
                ...state,
                postDiscussion: action.payload,
                postingDiscussion: false,

            };

        case QaConstants.POST_DISCUSSION_SUCCESS:
            return {
                ...state,
                error: action.error,
                postDiscussion: [],
                postingDiscussion: false,
            };



        case QaConstants.GET_DISCUSSIONS_REPLIES_PENDING:
            return {
                ...state,
            };

        case QaConstants.GET_DISCUSSIONS_REPLIES_SUCCESS:
            return {
                ...state,
                discussionRepliesData: action.payload,
            };

        case QaConstants.GET_DISCUSSIONS_REPLIES_FAILURE:
            return {
                ...state,
                error: action.error,
                discussionRepliesData: []
            };


        case QaConstants.DELETE_COMMENT_PENDING:
            return {
                ...state,
                deleteCommentSucess: false

            };

        case QaConstants.DELETE_COMMENT_SUCCESS:

            const filterRepliesAndDiscussions = (discussions, deletedIds) => {
                return discussions
                    .filter(discussion => {
                        return !deletedIds.includes(discussion.id);
                    })
                    .map(discussion => {
                        const filteredReplies = discussion.replies
                            ? discussion.replies.filter(reply => !deletedIds.includes(reply.id))
                            : [];

                        return {
                            ...discussion,
                            replies: filteredReplies,
                        };
                    });
            };

            const filteredDiscussionData = filterRepliesAndDiscussions(state.discussionData, action.payload.deletedIds);

            return {
                ...state,
                discussionData: filteredDiscussionData,
                deleteCommentSucess: true
            };
        case QaConstants.DELETE_COMMENT_FAILURE:
            return {
                ...state,
                error: action.error,
                deleteCommentSucess: false
            };


        case 'RESET_DISCUSSION_STATE':
            return {
                ...state,
                postingDiscussion: false,
                discussionLoading: false,
                deleteCommentSucess: false,
                discussionData: []
            };

        case 'RESET_GET_DISCUSSION_STATE':
            return {
                ...state,
                discussionLoading: false,
            };
        case 'RESET_DELETE_DISCUSSION_STATE':
            return {
                ...state,
                deleteCommentSucess: false,
            };



        default:
            return state;
            break;
    }
};





