export const lessonConstants = {
    GET_LESSONSONGS_FAILURE: "GET_LESSONSONGS_FAILURE",
    GET_LESSONSONGS_PENDING: "GET_LESSONSONGS_PENDING",
    GET_LESSONSONGS_SUCCESS: "GET_LESSONSONGS_SUCCESS",

    GET_LESSONHISTORY_SUCCESS: "GET_LESSONHISTORY_SUCCESS",
    GET_LESSONHISTORY_FAILURE: "GET_LESSONHISTORY_FAILURE",
    GET_LESSONHISTORY_PENDING: "GET_LESSONHISTORY_PENDING",

    GET_PLAYLIST_PENDING: "GET_PLAYLIST_PENDING",
    GET_PLAYLIST_SUCCESS: "GET_PLAYLIST_SUCCESS",
    GET_PLAYLIST_FAILURE: "GET_PLAYLIST_FAILURE",

    SAVE_PLAYLIST_FAILURE: "SAVE_PLAYLIST_FAILURE",
    SAVE_PLAYLIST_SUCCESS: "SAVE_PLAYLIST_SUCCESS",
    SAVE_PLAYLIST_PENDING: "SAVE_PLAYLIST_PENDING", 

    GET_LESSON_CATEGORY_PENDING: "GET_LESSON_CATEGORY_PENDING",
    GET_LESSON_CATEGORY_SUCCESS: "GET_LESSON_CATEGORY_SUCCESS",
    GET_LESSON_CATEGORY_FAILURE: "GET_LESSON_CATEGORY_FAILURE", 

    GET_TOP_CATEGORY_PENDING: "GET_TOP_CATEGORY_PENDING",
    GET_TOP_CATEGORY_SUCCESS: "GET_TOP_CATEGORY_SUCCESS",
    GET_TOP_CATEGORY_FAILURE: "GET_TOP_CATEGORY_FAILURE",

    GET_MAIN_CATEGORY_PENDING: "GET_MAIN_CATEGORY_PENDING",
    GET_MAIN_CATEGORY_SUCCESS: "GET_MAIN_CATEGORY_SUCCESS",
    GET_MAIN_CATEGORY_FAILURE: "GET_MAIN_CATEGORY_FAILURE",

    GET_TOPIC_PENDING: "GET_TOPIC_PENDING",
    GET_TOPIC_SUCCESS: "GET_TOPIC_SUCCESS",
    GET_TOPIC_FAILURE: "GET_TOPIC_FAILURE",
   
    GET_TOP_PICK_PENDING: "GET_TOP_PICK_PENDING",
    GET_TOP_PICK_SUCCESS: "GET_TOP_PICK_SUCCESS",
    GET_TOP_PICK_FAILURE: "GET_TOP_PICK_FAILURE",

    GET_KNOW_MORE_PENDING: "GET_KNOW_MORE_PENDING",
    GET_KNOW_MORE_SUCCESS: "GET_KNOW_MORE_SUCCESS",
    GET_KNOW_MORE_FAILURE: "GET_KNOW_MORE_FAILURE",

    GET_RECOMMENDED_COURSES_PENDING: "GET_RECOMMENDED_COURSES_PENDING",
    GET_RECOMMENDED_COURSES_SUCCESS: "GET_RECOMMENDED_COURSES_SUCCESS",
    GET_RECOMMENDED_COURSES_FAILURE: "GET_RECOMMENDED_COURSES_FAILURE",

    GET_LESSONSHIERARCY_PENDING: "GET_LESSONSHIERARCY_PENDING",
    GET_LESSONSHIERARCY_SUCCESS: "GET_LESSONSHIERARCY_SUCCESS",
    GET_LESSONSHIERARCY_FAILURE: "GET_LESSONSHIERARCY_FAILURE",

    GET_MEMBERSHIP_VIEW_PENDING: "GET_MEMBERSHIP_VIEW_PENDING",
    GET_MEMBERSHIP_VIEW_SUCCESS: "GET_MEMBERSHIP_VIEW_SUCCESS",
    GET_MEMBERSHIP_VIEW_FAILURE: "GET_MEMBERSHIP_VIEW_FAILURE",

    GET_COURSE_COMPLETION_PROGRESS_PENDING: "GET_COURSE_COMPLETION_PROGRESS_PENDING",
    GET_COURSE_COMPLETION_PROGRESS_SUCCESS: "GET_COURSE_COMPLETION_PROGRESS_SUCCESS",
    GET_COURSE_COMPLETION_PROGRESS_FAILURE: "GET_COURSE_COMPLETION_PROGRESS_FAILURE",

    GET_SONGS_COUNT_PENDING: "GET_SONGS_COUNT_PENDING",
    GET_SONGS_COUNT_SUCCESS: "GET_SONGS_COUNT_SUCCESS",
    GET_SONGS_COUNT_FAILURE: "GET_SONGS_COUNT_FAILURE",

    SAVE_POP_UP_PENDING: "SAVE_POP_UP_PENDING",
    SAVE_POP_UP_SUCCESS: "SAVE_POP_UP_SUCCESS",
    SAVE_POP_UP_FAILURE: "SAVE_POP_UP_FAILURE",

    GET_ASSESSMENT_PROGRESS_PENDING: "GET_ASSESSMENT_PROGRESS_PENDING",
    GET_ASSESSMENT_PROGRESS_SUCCESS: "GET_ASSESSMENT_PROGRESS_SUCCESS",
    GET_ASSESSMENT_PROGRESS_FAILURE: "GET_ASSESSMENT_PROGRESS_FAILURE",

    GET_SIMILAR_COURSE_PENDING:'GET_SIMILAR_COURSE_PENDING',
    GET_SIMILAR_COURSE_SUCCESS:'GET_SIMILAR_COURSE_SUCCESS',
    GET_SIMILAR_COURSE_FAILURE:'GET_SIMILAR_COURSE_FAILURE',

    GET_SONG_COUNT_PENDING:"GET_SONG_COUNT_PENDING",
    GET_SONG_COUNT_SUCCESS:"GET_SONG_COUNT_SUCCESS",
    GET_SONG_COUNT_FAILURE:"GET_SONG_COUNT_FAILURE",

    GET_SEARCH_PENDING:"GET_SEARCH_PENDING",
    GET_SEARCH_SUCCESS:"GET_SEARCH_SUCCESS",
    GET_SEARCH_FAILURE:"GET_SEARCH_FAILURE",

    GET_RECENT_SEARCH_PENDING:"GET_RECENT_SEARCH_PENDING",
    GET_RECENT_SEARCH_SUCCESS:"GET_RECENT_SEARCH_SUCCESS",
    GET_RECENT_SEARCH_FAILURE:"GET_RECENT_SEARCH_FAILURE",

    GET_SAVED_SEARCH_PENDING:"GET_SAVED_SEARCH_PENDING",
    GET_SAVED_SEARCH_SUCCESS:"GET_SAVED_SEARCH_SUCCESS",
    GET_SAVED_SEARCH_FAILURE:"GET_SAVED_SEARCH_FAILURE",

    GET_OTHER_SEARCH_PENDING:"GET_OTHER_SEARCH_PENDING",
    GET_OTHER_SEARCH_SUCCESS:"GET_OTHER_SEARCH_SUCCESS",
    GET_OTHER_SEARCH_FAILURE:"GET_OTHER_SEARCH_FAILURE",

    GET_RECENT_WATCH_DATA_PENDING:"GET_RECENT_WATCH_DATA_PENDING",
    GET_RECENT_WATCH_DATA_SUCCESS:"GET_RECENT_WATCH_DATA_SUCCESS",
    GET_RECENT_WATCH_DATA_FAILURE:"GET_RECENT_WATCH_DATA_FAILURE",

    GET_LEVEL_TYPE_PENDING:"GET_LEVEL_TYPE_PENDING",
    GET_LEVEL_TYPE_SUCCESS:"GET_LEVEL_TYPE_SUCCESS",
    GET_LEVEL_TYPE_FAILURE:"GET_LEVEL_TYPE_FAILURE",

    GET_KNOW_MORE_EXTRAS_PENDING:"GET_KNOW_MORE_EXTRAS_PENDING",
    GET_KNOW_MORE_EXTRAS_SUCCESS:"GET_KNOW_MORE_EXTRAS_SUCCESS",
    GET_KNOW_MORE_EXTRAS_FAILURE:"GET_KNOW_MORE_EXTRAS_FAILURE",

    GET_LESSON_EXTRAS_PENDING:"GET_LESSON_EXTRAS_PENDING",
    GET_LESSON_EXTRAS_SUCCESS:"GET_LESSON_EXTRAS_SUCCESS",
    GET_LESSON_EXTRAS_FAILURE:"GET_LESSON_EXTRAS_FAILURE"
    

}