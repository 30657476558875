import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { lessonSongs } from "../Action/lessonSongs.actions";

const HelmetComponent = (props) => {
	const dispatch = useDispatch();
	const pathname = useLocation();
	const slug = useParams();
	const getSeoData = useSelector((state) => state.seo.seoData);
	const instructor = useSelector((state) => state.instructor);
	const details = instructor?.instructorData?.data?.instructor;
	const songUserCount = useSelector((state) => state.lesson.songUserCount);
	const songChapter = props?.modules?.lessons[0]?.lessons;

	const itemListElement = props?.data?.map((item, index, array) => {
		const isLastItem = index === array.length - 1;
		return {
			"@type": "ListItem",
			"position": index + 1,
			"name": item?.name,
			"item": isLastItem ? `${process.env.REACT_APP_URL}${pathname?.pathname}` : `${process.env.REACT_APP_URL}${item?.url}`,
		};
	});

	let schemaData;
	if (pathname?.pathname === `/instructors/${details?.hash}`) {
		schemaData = {
			"@context": getSeoData?.schema?.context,
			"@type": getSeoData?.schema?.type,
			"name": getSeoData?.schema?.name,
			"url": getSeoData?.schema?.url,
			"image": getSeoData?.schema?.image,
			"sameAs": getSeoData?.schema?.sameAs,
			"jobTitle": getSeoData?.schema?.jobTitle,
			"worksFor": {
				"@type": getSeoData?.schema?.worksFor?.type,
				"name": getSeoData?.schema?.worksFor?.name,
			},
		};
	}

	// course-page
	let coursedata;
	if (props?.startCourse?.level >= 2 && props?.startCourse?.type === 'lessons') {
		coursedata = {
			"@context": "https://schema.org",
			"@type": "Course",
			"name": props?.startCourse?.title,
			"description": props?.startCourse?.content,
			"provider": {
				"@type": "Organization",
				"name": "Torrins",
				"sameAs": process.env.REACT_APP_URL,
			},
			"hasCourseInstance": {
				"@type": "CourseInstance",
				"courseMode": "online",
				"courseWorkload": props?.startCourse?.duration,
				"instructor": {
					"@type": "Person",
					"name": props?.startCourse?.instructor?.name,
				},
				"offers": {
					"@type": "Offer",
					"category": props?.startCourse?.free == 1 ? 'Free' : "Paid",
					"priceCurrency": props?.memberships?.userMembership[0]?.currency,
					"price": props?.memberships?.userMembership[0]?.price,
				},
				"url": props?.viewPage == 'course'
					? `${process.env.REACT_APP_URL}/${slug?.courses}-lessons/${props?.startCourse?.hash}-${props?.startCourse?.id}?parent=${props?.parentId}&child=${props?.childId}`
					: `${process.env.REACT_APP_URL}/${slug?.courses}-lessons/${props?.startCourse?.hash}-${props?.startCourse?.id}`,
			},
		};
	}

	let trackArray = songChapter?.map((chapter) => {
		const userInteractionCount = songUserCount?.completion_counts?.[chapter?.id] || 0;

		return {
			"@type": "MusicRecording",
			"name": chapter.title,
			"url": `${process.env.REACT_APP_URL}/${chapter?.hash}-${chapter?.id}`,
			"duration": chapter.duration,
			"audio": chapter.audio,
			"interactionStatistic": {
				"@type": "InteractionCounter",
				"interactionType": "https://schema.org/WatchAction",
				"userInteractionCount": userInteractionCount,
			},
		};
	});

	const chapterInteractionCount =
  songUserCount?.completion_counts?.[
    props?.startCourse?.lessons && props.startCourse.lessons.length > 0
      ? props.startCourse.lessons[0]?.id
      : undefined
  ] || 0;

	// song Schema
	let songSchema;
	if (props?.startCourse?.level > 2 && props?.startCourse?.type === 'songs') {
		songSchema = {
			"@context": "https://schema.org",
			"@type": "MusicGroup",
			"name": props.startCourse?.lessons?.length > 0 ? props?.startCourse?.title : props?.startCourse?.path[0]?.title,
			"image": props.startCourse?.lessons?.length > 0 ? props?.startCourse?.poster : props?.startCourse?.path[0]?.poster,
			"track": trackArray,
			"subjectOf": {
				"@type": "VideoObject",
				"name": props?.startCourse?.title,
				"description": props?.startCourse?.content,
				"thumbnailUrl": props?.startCourse?.poster,
				// "uploadDate": "2024-06-26",
				"duration": props?.startCourse?.duration,
				"interactionStatistic": {
					"@type": "InteractionCounter",
					"interactionType": "https://schema.org/WatchAction",
					"userInteractionCount": chapterInteractionCount,
				},
			},
		};
	}

	let homepageSchema = {
		"@context": "https://schema.org",
		"@type": "Organization",
		"name": "Torrins",
		"url": "https://www.torrins.com/",
		"logo": "https://www.torrins.com/assets/homepage/logo.png",
		"sameAs": [
			"https://twitter.com/Torrinsonline",
			"https://www.instagram.com/torrinsindia",
			"https://www.youtube.com/user/torrinsonline",
			"https://www.linkedin.com/company/torrinsindia/mycompany",
		],
	};

	let slugUrl;
	if (props?.startCourse?.type === 'lessons' && props?.startCourse?.level > 2) {
		slugUrl = `/${slug?.courses}-lessons/${props?.startCourse?.hash}-${props?.startCourse?.id}`;
	}
	if (props?.startCourse?.type === 'lessons' && props?.startCourse?.level === 2) {
		slugUrl = props?.viewPage !== 'course'
			? `/${slug?.courses}-lessons/${props?.startCourse?.hash}-${props?.startCourse?.id}`
			: `/${pathname?.pathname}`;
	}
	if (props?.startCourse?.type === 'songs') {
		slugUrl = props?.startCourse?.lessons?.length > 0
			? `/${slug?.courses}-lessons/${props?.startCourse?.hash}-${props?.startCourse?.id}`
			: `/${slug?.courses}-lessons/${props?.startCourse?.path[0]?.hash}-${props?.startCourse?.path[0]?.id}`;
	}
	if (props?.startCourse?.type === 'lessons' && props?.startCourse?.level > 3 && props?.viewPage === 'course') {
		slugUrl = `/${slug?.courses}-lessons/${props?.startCourse?.hash}-${props?.startCourse?.id}?parent=${props?.parentId}&child=${props?.childId}`;
	}

	return (
		<Helmet>
			<title>{getSeoData?.title}</title>
			{process.env.REACT_APP_ENVIRONMENT === "dev" ? (
				<meta name="robots" content="noindex, nofollow" />
			) : (
				""
			)}
			<meta name="description" content={getSeoData?.description} />
			<meta name="keywords" content={getSeoData?.keyword} />
			<link
				rel="canonical"
				href={
					props?.startCourse?.path?.length > 0
						? `${process.env.REACT_APP_URL}${slugUrl}`
						: `${process.env.REACT_APP_URL}${pathname?.pathname}`
				}
			/>
			
			

			{/* Structured Data */}
			<script type="application/ld+json">
				{JSON.stringify(homepageSchema)}
			</script>

			{/* Instructors */}
			<script type="application/ld+json">
				{JSON.stringify(schemaData)}
			</script>

			{/* Breadcrumbs */}
			<script type="application/ld+json">
				{JSON.stringify({
					"@context": "https://schema.org/",
					"@type": "BreadcrumbList",
					"itemListElement": itemListElement,
				})}
			</script>

			{/* course page */}
			<script type="application/ld+json">
				{JSON.stringify(coursedata)}
			</script>

			{/* song schema */}
			<script type="application/ld+json">
				{JSON.stringify(songSchema)}
			</script>

			{/* Open Graph Meta Tags */}
			<meta property="og:site_name" content="Torrins" />
			<meta property="og:title" content={getSeoData?.title} />
			<meta property="og:description" content={getSeoData?.description} />
			<meta property="og:url" content={
					props?.startCourse?.path?.length > 0
						? `${process.env.REACT_APP_URL}${slugUrl}`
						: `${process.env.REACT_APP_URL}${pathname?.pathname}`
				} />
			<meta property="og:type" content={props?.startCourse?.level >= 4 ? 'video' : 'website'} />
			<meta property="og:image" content="/assets/img/Snackbar/snackbarlogo.svg" />
		</Helmet>
	);
};

export default HelmetComponent;
