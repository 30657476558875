import React, { Fragment, useEffect, useState } from 'react';
import { faq } from '../../Action/Faq.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const MembershipFaq = ({ premiumPage, faqs, coursePage ,slugName }) => {
  const faqData = useSelector((state) => state.faq.faqData);
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (coursePage) {
      dispatch(faq.getFaqData('8'));

    } else if (!premiumPage) {
      dispatch(faq.getFaqData('3'));

    }
  }, [coursePage]);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  }

  return (
    <>
      <div className={`frequently-asked-section ${coursePage && "course-faqs mt-0"}`}>
        <h3>Frequently Asked Questions</h3>
        {/* for premium page with diff content same component used */}
        {
          !premiumPage ? (
            faqData?.data?.data?.data?.length > 0 ? (
                faqData.data.data.data
                ?.filter((data) => {
                  if (coursePage) {
                    return slugName?.toLowerCase().includes(data?.tags?.toLowerCase());
                  }
                  return true; 
                })  ?.map((query, index) => (
                <Fragment key={index}>
                  <button
                    className={`accordion ${index === activeIndex ? 'active' : ''}`}
                    onClick={() => handleAccordionClick(index)}
                  >
                    {query?.questions}
                  </button>
                  <div className="panel" style={{
                    maxHeight: index === activeIndex ? '100%' : '0',
                    overflow: 'hidden',
                    transition: 'max-height 0.3s ease'
                  }}>
                    <p>{query?.answers}</p>
                    <div className="d-flex gap-2 align-items-center my-3">
                      {/* <img src="/assets/img/Member/thumb.svg" alt="" /> */}
                      {/* <div className="helpful-div">1.5K found helpful</div> */}
                    </div>
                  </div>
                </Fragment>
              ))
            ) : (
              ''
            )
          ) : (
            faqs?.map((query, index) => (
              <Fragment key={index}>
                <button
                  className={`accordion ${index === activeIndex ? 'active' : ''}`}
                  onClick={() => handleAccordionClick(index)}
                >
                  {query?.question}
                </button>
                <div className="panel" style={{
                  maxHeight: index === activeIndex ? '100%' : '0',
                  overflow: 'hidden',
                  transition: 'max-height 0.3s ease'
                }}>
                  <p>{query?.answer}</p>
                  <div className="d-flex gap-2 align-items-center my-3">

                  </div>
                </div>
              </Fragment>
            ))
          )
        }

        {coursePage &&
          <div class="new-faq-contact mt-4">
            <div class="title-with-des">
              <h2 className="m-0">Could find what you were looking for?</h2>
              <p>Write to us we will get back to you</p>
            </div>
            <Link to="/support">Contact Us</Link>
          </div>

        }

      </div>
      {!premiumPage && !coursePage && <div class="view-all-btn faq-liness"><Link to="/faq">Read All FAQs</Link></div>}
    </>
  );
};

export default MembershipFaq;
