import { membershipService } from "../Adapters/membership.services";
import { membershipConstants } from '../Constants/Membership'
import { toast } from "react-toastify";
import CustomErrorToast from "../components/CustomToaster/CustomErrorToast";
import CustomSuccessToast from "../components/CustomToaster/CustomSuccessToaster";

export const membership = {
    getMembershipData,
    isValidCoupon,
    getAvailablePayment,
    getInstruments,
    proceedPayment,
    getPaymentDetails,
    cancelSubscription,
    getAvailableCoupon,
    proceedGiftCardPayment,
    getGiftcardTemplates,
    getPaymentDetailsGiftCard
}

async function handleRequest(endpoint, params, successType, failureType, headers = null) {
    try {
        const response = headers
            ? await membershipService.sendTokenInHeader(endpoint, params, headers)
            : await membershipService.sendRequest(endpoint, params);
            
        if (response?.status === 200 && response?.data?.code === "SUCCESS") {
            if (
                response?.data?.message === "Login successful!" ||
                response?.data?.message === "Logout successful!"
            ) {
                console.log('');
            } else {
                toast(<CustomSuccessToast message={response?.data?.message} />)
            }
            return { type: successType, payload: response?.data };
        } else if (response?.status === 201 && response?.data?.code === "SUCCESS") {
            toast(<CustomSuccessToast message={response?.data?.message} />)
            return { type: successType, payload: response?.data };
        } else if (response?.status === 404 && response?.data?.code === "ERROR") {
            if (response?.data?.errors) {
                for (const key in response?.data?.errors) {
                    if (response?.data?.errors.hasOwnProperty(key)) {
                        response?.data?.errors[key].forEach((errorMessage) => {
                            toast(<CustomErrorToast message={errorMessage} />)
                        });
                    }
                }
            } else {
                if(response?.data?.message == "User not found or invalid token."){
                    console.log('error');
                } else {
                    toast(<CustomErrorToast message={response?.data?.message} />)
                }
               
            }
            
            return { type: failureType, payload: response?.data };
        } else if (response?.status === 400 && response?.data?.code === "ERROR") {
            if (response?.data?.errors) {
                for (const key in response?.data?.errors) {
                    if (response?.data?.errors.hasOwnProperty(key)) {
                        response?.data?.errors[key].forEach((errorMessage) => {
                            toast(<CustomErrorToast message={errorMessage} />)
                        });
                    }
                }
            } else {
                toast(<CustomErrorToast message={response?.data?.message} />)
            }
            return { type: failureType, payload: response?.data };
        } else if (response?.status === 401 && response?.data?.code === "ERROR") {
            if (response?.data?.errors) {
                for (const key in response?.data?.errors) {
                    if (response?.data?.errors.hasOwnProperty(key)) {
                        response?.data?.errors[key].forEach((errorMessage) => {
                            toast(<CustomErrorToast message={errorMessage} />)
                        });
                    }
                }
            } else {
                toast(<CustomErrorToast message={response?.data?.message} />)
            }
            return { type: failureType, payload: response?.data };
        } else if (response?.status === 500 ) {
            toast(<CustomErrorToast message={'Internal server error'} />)
            return { type: failureType, payload: response?.data };
        } else {
            toast(<CustomErrorToast message={'Invalid api request'} />)
            return { type: failureType, payload: response?.data };
        }
    } catch (error) {
        toast(<CustomErrorToast message={'Some error occured'} />)
    }
}

async function isValidCoupon(params) {
    return handleRequest("/api/verify-code", params, membershipConstants.COUPON_CODE_SUCCESS, membershipConstants.COUPON_CODE_FAILURE);
}

async function proceedPayment(params, headers) {
    const response = await membershipService
        .sendTokenInHeader('/api/payment/checkout', params, headers);

        if (response?.status === 200 && response?.data?.code === "SUCCESS") {
            return { 'data': response?.data, 'message': '' };
        } else if (response?.status === 400 && response?.data?.code === "ERROR") {
            if (response?.data?.errors) {
                for (const key in response?.data?.errors) {
                    if (response?.data?.errors.hasOwnProperty(key)) {
                        response?.data?.errors[key].forEach((errorMessage) => {
                            toast(<CustomErrorToast message={errorMessage} />)
                        });
                    }
                }
            } else {
                toast(<CustomErrorToast message={response?.data?.message} />)
            }
            return { 'data': false };
        }
    // if (response?.status === 200 && response?.data?.code === "SUCCESS") {
        
    // } else if ((response?.status === 400 || response?.status === 404) && response?.data?.code === "ERROR") {
    //     return { 'data': false, 'message': response.data.message };
    // }
}

async function cancelSubscription(params, headers) {
    return (dispatch) => {
        dispatch(request());
        membershipService
        .sendTokenInHeader('/api/subscription/cancel', params, headers)
        .then((response) => {
            dispatch(success(response.data));
            
            if (response?.status === 200 && response?.data?.code === "SUCCESS") {
            } else if ((response?.status === 400 || response?.status === 404) && response?.data?.code === "ERROR") {
                if (response?.data?.errors) {
                    for (const key in response?.data?.errors) {
                        if (response?.data?.errors.hasOwnProperty(key)) {
                            response?.data?.errors[key].forEach((errorMessage) => {
                            });
                        }
                    }
                } else {
                }
            }
        })
        .catch((error) => {
            dispatch(errorCancelSubscription(error));
        });
    };
  
    function errorCancelSubscription(error) {
        return { type: membershipConstants.CANCEL_SUBSCRIPTION_FAILURE, error: error };
    }
  
    function request() {
        return { type: membershipConstants.CANCEL_SUBSCRIPTION_PENDING };
    }
  
    function success(data) {
        return { type: membershipConstants.CANCEL_SUBSCRIPTION_SUCCESS, payload: data };
    }

}

function getMembershipData(params, headers) {
    return (dispatch) => {
        dispatch(request());
        membershipService
        .getMembershipData('/api/get-membership', params, headers)
        .then((response) => {
            dispatch(success(response?.data?.data));
        })
        .catch((error) => {
            dispatch(errorMembership(error));
        });
    };
  
    function errorMembership(error) {
        return { type: membershipConstants.GET_MEMBERSHIP_FAILURE, error: error };
    }
  
    function request() {
        return { type: membershipConstants.GET_MEMBERSHIP_PENDING };
    }
  
    function success(data) {
        return { type: membershipConstants.GET_MEMBERSHIP_SUCCESS, payload: data };
    }
}

function getAvailablePayment(currency, params, headers) {
    return (dispatch) => {
        dispatch(request());
        membershipService
        .getAvailablePayment(`/api/available-payment?currency=${currency}`, params, headers)
        .then((response) => {
            dispatch(success(response?.data?.data));
        })
        .catch((error) => {
            dispatch(errorAvailablePayment(error));
        });
    };
  
    function errorAvailablePayment(error) {
        return { type: membershipConstants.GET_AVAILABLE_PAYMENT_FAILURE, error: error };
    }
  
    function request() {
        return { type: membershipConstants.GET_AVAILABLE_PAYMENT_REQUEST };
    }
  
    function success(data) {
        return { type: membershipConstants.GET_AVAILABLE_PAYMENT_SUCCESS, payload: data };
    }
}

function getInstruments(params) {
    return (dispatch) => {
        dispatch(request());
        membershipService
        .getInstruments('/api/get-instrument', params)
        .then((response) => {
            dispatch(success(response.data));
        })
        .catch((error) => {
            dispatch(errorInstruments(error));
        });
    };
  
    function errorInstruments(error) {
        return { type: membershipConstants.GET_INSTRUMENT_FAILURE, error: error };
    }
  
    function request() {
        return { type: membershipConstants.GET_INSTRUMENT_REQUEST };
    }
  
    function success(data) {
        return { type: membershipConstants.GET_INSTRUMENT_SUCCESS, payload: data };
    }
}

function getPaymentDetails(id, headers) {
    return (dispatch) => {
        dispatch(request());
        membershipService
        .getPaymentDetails(`/api/payment/membership-details/${id}`, headers)
        .then((response) => {
            dispatch(success(response?.data?.data));
        })
        .catch((error) => {
            dispatch(errorPayment(error));
        });
    };
  
    function errorPayment(error) {
        return { type: membershipConstants.GET_PAYMENT_DETAILS_FAILURE, error: error };
    }
  
    function request() {
        return { type: membershipConstants.GET_PAYMENT_DETAILS_PENDING };
    }
  
    function success(data) {
        return { type: membershipConstants.GET_PAYMENT_DETAILS_SUCCESS, payload: data };
    }
}

function getAvailableCoupon(id, headers) {
    return (dispatch) => {
        dispatch(request());
        membershipService
        .getPaymentDetails(`/api/getApplicableCouponList?membership=${id}`, headers)
        .then((response) => {
            dispatch(success(response?.data?.data));
        })
        .catch((error) => {
            dispatch(errorAvailableCoupon(error));
        });
    };
  
    function errorAvailableCoupon(error) {
        return { type: membershipConstants.GET_AVAILABLE_COUPON_FAILURE, error: error };
    }
  
    function request() {
        return { type: membershipConstants.GET_AVAILABLE_COUPON_PENDING };
    }
  
    function success(data) {
        return { type: membershipConstants.GET_AVAILABLE_COUPON_SUCCESS, payload: data };
    }
}



async function proceedGiftCardPayment(params, headers) {
    const response = await membershipService
        .sendTokenInHeader('/api/payment/giftCardCheckout', params, headers);

        if (response?.status === 200 && response?.data?.code === "SUCCESS") {
            return { 'data': response?.data, 'message': '' };
        } else if (response?.status === 400 && response?.data?.code === "ERROR") {
            if (response?.data?.errors) {
                for (const key in response?.data?.errors) {
                    if (response?.data?.errors.hasOwnProperty(key)) {
                        response?.data?.errors[key].forEach((errorMessage) => {
                            toast(<CustomErrorToast message={errorMessage} />)
                        });
                    }
                }
            } else {
                toast(<CustomErrorToast message={response?.data?.message} />)
            }
            return { 'data': false };
        }

}


function getGiftcardTemplates(headers) {
    return (dispatch) => {
        dispatch(request());
        membershipService
        .getPaymentDetails(`/api/getGiftcardTemplates`, headers)
        .then((response) => {
            dispatch(success(response?.data?.data));
        })
        .catch((error) => {
            dispatch(errors(error));
        });
    };
  
    function errors(error) {
        return { type: membershipConstants.GET_GIFTCARD_TEMPLATES_FAILURE, error: error };
    }
  
    function request() {
        return { type: membershipConstants.GET_GIFTCARD_TEMPLATES_PENDING };
    }
  
    function success(data) {
        return { type: membershipConstants.GET_GIFTCARD_TEMPLATES_SUCCESS, payload: data };
    }
}


function getPaymentDetailsGiftCard(id , headers) {
    return (dispatch) => {
        dispatch(request());
        membershipService
        .getPaymentDetails(`/api/getGiftcardTransactionDetails?transaction_id=${id}`, headers)
        .then((response) => {
            dispatch(success(response?.data?.data));
        })
        .catch((error) => {
            dispatch(errorPayment(error));
        });
    };
  
    function errorPayment(error) {
        return { type: membershipConstants.GET_PAYMENT_DETAILS_FAILURE, error: error };
    }
  
    function request() {
        return { type: membershipConstants.GET_PAYMENT_DETAILS_PENDING };
    }
  
    function success(data) {
        return { type: membershipConstants.GET_PAYMENT_DETAILS_SUCCESS, payload: data };
    }
}