import { post, get } from "./xhr";

export const webinarService = {
    getWebinar,
    webinarSaveIntent
};

function webinarSaveIntent(url, params, headers) {
  return post(url, params, headers);
}

function getWebinar(url, headers) {
  return get(url, headers);
}
