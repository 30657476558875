import { error } from "jquery";
import { feedbackConstants } from "../Constants/feedbackConstants";

const initialState = { 
    feedbackLoading: false,
    feedback:[],
    upload:{},
    similarSongs:{},
    feedbackLoadMoreData:[],
    similarLoading:false,
    activeTab:'loop',
    saveVedio:false,
    uploadProgress:[],
    notUploadVedio: false
}

export const feedbackReducer = ( state=initialState, action ) => {
    switch(action.type) {
        case feedbackConstants.GET_FEEDBACK_PENDING:
            return {
                ...state,
                feedbackLoading: true,
                feedback:[],
            };
            break;

        case feedbackConstants.GET_FEEDBACK_SUCCESS:
            let updatedMoreData = [...state.feedbackLoadMoreData];
            updatedMoreData = (action.payload?.data) ? updatedMoreData.concat(action.payload?.data) : [];

            return {
                ...state,
                feedbackLoading: false,
                feedback: action.payload,
                feedbackLoadMoreData:updatedMoreData
               
            };
            break;

        case feedbackConstants.GET_FEEDBACK_FAILURE:
            return {
                ...state,
                feedbackLoading: false,
            };
            break;

        case feedbackConstants. UPLOAD_PENDING:
            return {
                ...state,
                saveVedio: true,
                upload:{}
                
            };
            break;

        case feedbackConstants.UPLOAD_SUCCESS:

            return {
                ...state,
                saveVedio: false,
                upload: action.payload,
               
            };
            break;

        case feedbackConstants.UPLOAD_FAILURE:
            return {
                ...state,
                saveVedio: false,
                upload:'error'
            };
            break;

        case feedbackConstants.GET_SIMILAR_SONGS_PENDING:
            return {
                ...state,
                similarLoading: true,
                
            };
            break;

        case feedbackConstants.GET_SIMILAR_SONGS_SUCCESS:

            return {
                ...state,
                similarLoading: false,
                similarSongs: action.payload,
               
            };
            break;

        case feedbackConstants.GET_SIMILAR_SONGS_FAILURE:
            return {
                ...state,
                similarLoading: false,
            };
            break;
            

        case 'FEEDBACK_DATA':
            return { 
                ...state,
                feedbackLoadMoreData: [],
            };
            break;

            case feedbackConstants. UPLOAD_PROGRESS_PENDING:
            return {
                ...state
                
            };
            break;

        case feedbackConstants.UPLOAD_PROGRESS_SUCCESS:

            return {
                ...state,
                uploadProgress: action.payload,
               
            };
            break;

        case feedbackConstants.UPLOAD_PROGRESS_FAILURE:
            return {
                ...state,
            };
            break;

        default:
            return state;
        }
    }