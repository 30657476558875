import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { faq } from "../../Action/Faq.actions";
import { useDispatch, useSelector } from "react-redux";

const JoinThisWebinarSection = (props) => {
    const dispatch = useDispatch();
    const faqData = useSelector((state) => state.faq.faqData);
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeSlide, setActiveSlide] = useState(0); // Manage the active slide
    const sliderRef = React.createRef();
    const [activeArrow, setActiveArrow] = useState(''); // Manage the active arrow

    // Function to handle next/previous slide and set active arrow
    const handleArrowClick = (direction) => {
        if (direction === 'right') {
            setActiveArrow('right');
            sliderRef.current.slickNext();
        } else if (direction === 'left') {
            setActiveArrow('left');
            sliderRef.current.slickPrev();
        }
    };

    const settings = {
        ...props?.settings,
        ref: sliderRef, // Reference to control slider
        beforeChange: (oldIndex, newIndex) => setActiveSlide(newIndex), // Update active slide index
    };


    const toggleAccordion = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? -1 : index));
    };

    useEffect(() => {
        return() => {
            dispatch(faq.getFaqData("7"));
        }
       
    }, []);



    return (
        <section className="second-section">
            <div className="row sub-second">
                <div class="webinar-section">
                    <h2>Why join this webinar</h2>
                    <div class="reasons-container">
                        <div class="reason">
                            <div
                                className="circle-reason"
                                style={{
                                    height: "65px",
                                    width: "65px",
                                    borderRadius: "50%",
                                }}
                            >
                                <img
                                    src="/assets/img/webinar/1-icon.png"
                                    alt="Expert Musicians Icon"
                                />
                            </div>
                            <h3>Expert Musicians</h3>
                            <p>
                                Our professional guitarist will teach you the
                                basics, no experience needed
                            </p>
                        </div>
                        <div class="reason">
                            <div
                                className="circle-reason"
                                style={{
                                    height: "65px",
                                    width: "65px",
                                    borderRadius: "50%",
                                }}
                            >
                                <img
                                    src="/assets/img/webinar/2-icon.png"
                                    alt="Guitarists of all levels Icon"
                                />
                            </div>
                            <h3>Perfect for guitarists of all levels</h3>
                            <p>
                                We start with the very basics to advance,
                                something to learn for all
                            </p>
                        </div>
                        <div class="reason">
                            <div
                                className="circle-reason"
                                style={{
                                    height: "65px",
                                    width: "65px",
                                    borderRadius: "50%",
                                }}
                            >
                                <img
                                    src="/assets/img/webinar/3-icon.png"
                                    alt="Interactive & Live Icon"
                                />
                            </div>
                            <h3>Interactive & Live</h3>
                            <p>
                                Ask questions, get instant feedback, and play
                                along in real-time
                            </p>
                        </div>
                        {/* <div class="reason">
                            <div
                                className="circle-reason"
                                style={{
                                    height: "65px",
                                    width: "65px",
                                    borderRadius: "50%",
                                }}
                            >
                                <img
                                    src="/assets/img/webinar/1-icon.png"
                                    alt="Guitarists of all levels Icon"
                                />
                            </div>
                            <h3>Perfect for guitarists of all levels</h3>
                            <p>
                                We start with the very basics to advance,
                                something to learn for all
                            </p>
                        </div>
                        <div class="reason">
                            <div
                                className="circle-reason"
                                style={{
                                    height: "65px",
                                    width: "65px",
                                    borderRadius: "50%",
                                }}
                            >
                                <img
                                    src="/assets/img/webinar/1-icon.png"
                                    alt="Guitarists of all levels Icon"
                                />
                            </div>
                            <h3>Perfect for guitarists of all levels</h3>
                            <p>
                                We start with the very basics to advance,
                                something to learn for all
                            </p>
                        </div> */}
                    </div>
                    <div class="submit-btn">
                        <button class="btn action-btn" onClick={props?.handleJoinClick}>Join for Free</button>
                    </div>
                </div>

                {/* <Slider {...settings} className="success-container">
                    {props?.testinomialData?.length > 0 ? props?.testinomialData?.map((testinomial) => {
                        return(
                            <div>
                            <div className="success-stories">
                                <h1>{testinomial?.title}</h1>
                                <p className="border-bottom">
                                    {testinomial?.text}
                                </p>
                            </div>
                            <div className="story-intro">
                                <img src='/assets/img/default_user.png' alt="Learner 3" />
                                <div className="self-intro">
                                    <div>
                                        <h1>{testinomial?.testimonial_by}</h1>
                                        <p>{testinomial?.country}</p>
                                    </div>
                                    <div className="arrow">
                                        <a
                                            href="javascript:void(0)"
                                            className={`left-arrow ${activeArrow === 'left' ? 'active' : ''} ${activeSlide === 0 ? 'disabled' : ''}`}
                                            onClick={() => handleArrowClick('left')}
                                        >
                                            {activeArrow === 'left' ?  <img src="/assets/img/webinar/Left Black.svg" alt="" />:  <img src="/assets/img/webinar/arrow-left.png" alt="" />}
                                           
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className={`right-arrow ${activeArrow === 'right' ? 'active' : ''} ${activeSlide === 2 ? 'disabled' : ''}`}
                                            onClick={() => handleArrowClick('right')}
                                        >
                                             {activeArrow === 'right' ? <img src="/assets/img/webinar/arrow-right.png" alt="" /> : <img src="/assets/img/webinar/Right Yellow.svg" alt="" />}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    }):''}
                </Slider> */}

                <div className="frequently-asked-section">
                    <h3>Answers to common questions</h3>
                    {faqData?.data?.data?.data?.length > 0
                        ? faqData?.data?.data?.data?.map(
                            (webinarFaq, index) => {
                                return (
                                    <div key={index} style={{marginTop: '22px'}}>
                                        {/* Add active class if the current accordion is open */}
                                        <button
                                            className={`accordion ${activeIndex === index
                                                    ? "active"
                                                    : ""
                                                }`}
                                            onClick={() =>
                                                toggleAccordion(index)
                                            }
                                        >
                                            {` ${index + 1}. ${webinarFaq?.questions
                                                }`}
                                        </button>
                                        {/* Panel should be open only if the current accordion is active */}
                                        <div
                                            className="panel"
                                            style={{
                                                maxHeight:
                                                    activeIndex === index
                                                        ? "200px"
                                                        : "0",
                                            }}
                                        >
                                            <p>{webinarFaq?.answers}</p>
                                            {/* <div className="like-sec">
                                                <div className="d-flex gap-2 align-items-center my-3 helpful">
                                                    <img
                                                        src="/assets/img/webinar/thumb.png"
                                                        alt=""
                                                    />
                                                    <div className="helpful-div">
                                                        This was helpful
                                                    </div>
                                                </div>
                                                <div className="d-flex gap-2 align-items-center my-3 found">
                                                    <div className="found-div">
                                                        100 people found this
                                                        useful
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                );
                            }
                        )
                        : ""}
                </div>
                {/* <div className='slots'>
                 <div className='sub-slots'>
                     <h1>Hurry limited slots available</h1>
                     <div className='join-free'>
                         <div class="submit-btn join-free-btn" style={{marginTop: '22px'}}>
                             <button class="btn action-btn">Register Now</button>
                         </div>
                     </div>
                 </div>
                
             </div> */}
            </div>
        </section>
    );
};

export default JoinThisWebinarSection;
