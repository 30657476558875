import { post, get } from "./xhr";

export const noteService = {
    getNotes,
    deleteNotes,
    sendTokenInHeader
};

function getNotes(url, headers) {
  return get(url, headers);
}

function deleteNotes(url, headers) {
  return get(url, headers);
}

function sendTokenInHeader(url, params, headers) {
  return post(url, params, headers);
}