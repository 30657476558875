export const homepageConst = {    
    GET_HOMEPAGE_PENDING: "GET_HOMEPAGE_PENDING",
    GET_HOMEPAGE_SUCCESS: "GET_HOMEPAGE_SUCCESS",
    GET_HOMEPAGE_FAILURE: "GET_HOMEPAGE_FAILURE",

    GET_COURSES_LINKS_PENDING: "GET_COURSES_LINKS_PENDING",
    GET_COURSES_LINKS_SUCCESS: "GET_COURSES_LINKS_SUCCESS",
    GET_COURSES_LINKS_FAILURE: "GET_COURSES_LINKS_FAILURE",


    GET_RECENT_LESSONS_PENDING: "GET_RECENT_LESSONS_PENDING",
    GET_RECENT_LESSONS_SUCCESS: "GET_RECENT_LESSONS_SUCCESS",
    GET_RECENT_LESSONS_FAILURE: "GET_RECENT_LESSONS_FAILURE",


    GET_RECENT_COURSE_LESSONS_PENDING: "GET_RECENT_COURSE_LESSONS_PENDING",
    GET_RECENT_COURSE_LESSONS_SUCCESS: "GET_RECENT_COURSE_LESSONS_SUCCESS",
    GET_RECENT__COURSE_LESSONS_FAILURE: "GET_RECENT__COURSE_LESSONS_FAILURE",

}