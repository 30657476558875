import React from 'react';

export const Button = (props) => {
    return (
        <button 
            type='submit'
            className={props?.className} 
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.label}
        </button>
    );
};

export default Button;
