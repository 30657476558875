import { faqService } from "../Adapters/Faq.service";
import { faqConstants } from "../Constants/FaqConstants";
import { toast } from "react-toastify";
import CustomErrorToast from "../components/CustomToaster/CustomErrorToast";
import CustomSuccessToast from "../components/CustomToaster/CustomSuccessToaster";

export const faq = {
    contactUs,
    getFaqData,
    getFaqCategoryData,
    getCountryCode
}

function getCountryCode(params = {}) {
    return (dispatch) => {
        dispatch(request());
        faqService
        .getCountryCode('/api/country-code', params)
        .then((response) => {
            dispatch(success(response.data?.data));
        })
        .catch((error) => {
            dispatch(errorCountryCode(error));
        });
    };
  
    function errorCountryCode(error) {
        return { type: faqConstants.COUNTRY_CODE_FAILURE, error: error };
    }
  
    function request() {
        return { type: faqConstants.COUNTRY_CODE_PENDING };
    }
  
    function success(data) {
        return { type: faqConstants.COUNTRY_CODE_SUCCESS, payload: data };
    }
}

async function contactUs(data) {
    const response = await faqService.contactUs("/api/contact-us", data);
    if (response?.status === 200) {
        toast(<CustomSuccessToast message={response?.data?.message} />);
      return { type: faqConstants.CONTACT_SUCCESS, payload: response.data };
    } else {
        if (response?.data?.errors) {
            for (const key in response?.data?.errors) {
                if (response?.data?.errors.hasOwnProperty(key)) {
                    response?.data?.errors[key].forEach((errorMessage) => {
                        toast(<CustomErrorToast message={errorMessage} />);
                    });
                }
            }
        } else {
            toast(<CustomErrorToast message={response?.data?.message} />);
        }
      return { type: faqConstants.CONTACT_FAILURE };
    }
}

function getFaqCategoryData() {
  return (dispatch) => {
      dispatch(request());
      faqService
      .getFaqData('/api/faq-categories')
      .then((response) => {
          dispatch(success(response.data));
      })
      .catch((error) => {
          dispatch(errorFaqCategory(error));
      });
  };

  function errorFaqCategory(error) {
      return { type: faqConstants.FAQ_CATEGORY_FAILURE, error: error };
  }

  function request() {
      return { type: faqConstants.FAQ_CATEGORY_PENDING };
  }

  function success(data) {
      return { type: faqConstants.FAQ_CATEGORY_SUCCESS, payload: data };
  }
}

function getFaqData(categoryId, tags = '',) {

  let params = (categoryId) ? `?category_id=${categoryId}` : '';

    if (tags) {
        params += `&tags=${tags}`;
    }
    
  return (dispatch) => {
      dispatch(request());
      faqService
      .getFaqData(`/api/faq${params}`)
      .then((response) => {
          dispatch(success(response.data));
      })
      .catch((error) => {
          dispatch(errorFaq(error));
      });
  };

  function errorFaq(error) {
      return { type: faqConstants.FAQ_FAILURE, error: error };
  }

  function request() {
      return { type: faqConstants.FAQ_PENDING };
  }

  function success(data) {
      return { type: faqConstants.FAQ_SUCCESS, payload: data };
  }
}