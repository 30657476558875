import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { webinarSchema } from "../../ValidationSchema/ContactUsSchema";
import { FormInput } from "../../Utility/FormInput";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../Action/auth.actions";
import { authConstants } from "../../Constants/Auth";
import { WebinarConstants } from "../../Constants/WebinarConstants";
import { webinarAction } from "../../Action/webinar.action";
import Slider from "react-slick";
import useWindowDimensions from "../../Helpers/useWindowDimensions";

const schema = webinarSchema;

const LearnWebinarSection = (props) => {
    const dispatch = useDispatch();
    const { windowWidth } = useWindowDimensions();

    const authState = useSelector((state) => state.auth.userDetails);
    const logoutState = useSelector((state) => state.auth);
    const getUserData = useSelector((state) => state.auth.userProfile);
    const countryCode = useSelector((state) => state.auth.countryCode);
    const saveWebinarLoading = useSelector(
        (state) => state.webinar.saveWebinarLoading
    );
    const [phoneCode, setPhoneCode] = useState("91");
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState(false);
    const [token, setToken] = useState(
        authState?.userDetails?.hash ?? authState?.userDetails?.token
    );
    const [receiveUpdates, setReceiveUpdates] = useState(false);


    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onSubmit",
    });

    useEffect(() => {
        if (logoutState.auth === false) {
            reset({
                name: "",
                email: "",
                phone_number: "",
                phone_code: "91",
            });
            setPhone(""); // Also clear any state-based input fields
            setReceiveUpdates(false); // Reset checkbox
        }
    }, [logoutState.auth, reset]);

    const handleCheckboxChange = (e) => {
        setReceiveUpdates(e.target.checked);
    };

    const onSubmit = (data) => {
        const phoneToSubmit = phone || getUserData?.user_data?.phone;

        if (phoneCode && !phoneToSubmit) {
            setPhoneError("Please enter the phone number.");
            return;
        }

        let requestData = {
            ...data,
            webinar: props?.webinarData[0]?.id,
            receive_updates: receiveUpdates,
        };

        if (phoneToSubmit) {
            requestData = {
                ...requestData,
                phone_code: phoneCode,
                phone_number: phoneToSubmit,
            };
        }

        dispatch({ type: WebinarConstants.SAVE_INTENT_PENDING });
        dispatch(
            webinarAction.saveWebinarIntent(requestData, { Token: token })
        );
    };


    useEffect(() => {

        dispatch({ type: authConstants.RESET_FLAGS });
        return() => {
            dispatch(auth.getCountryCode());
        }
    }, []);

    const countryOptions = countryCode?.data?.map((country) => ({
        phoneCode: country?.phonecode,
        label: country?.name,
        phonecode: country?.phonecode,
        sortname: country?.sortname,
    }));

    const filteredCountryOptions = countryOptions?.filter(
        (option) =>
            option.phonecode &&
            option.phonecode.toString().length >= 1 &&
            option.phonecode.toString().length <= 3
    );

    useEffect(() => {
        if (countryCode?.data?.length > 0) {
            window.$("#single-select-field").select2({
                theme: "bootstrap-5",
                width: window.$(this).data("width")
                    ? window.$(this).data("width")
                    : window.$(this).hasClass("w-100")
                        ? "100%"
                        : "style",
                placeholder: window.$(this).data("placeholder"),
            });

            window.$("#single-select-field").on("change", function (e) {
                setPhoneCode(e.target.value);
            });
        }
    }, [countryCode]);

    const handlePhoneChange = (e) => {
        phoneValidation(e.target.value.replace(/\D+/g, ""));
    };

    function phoneValidation(phoneValue) {
        setPhone(phoneValue);
        if (phoneValue == "") {
            setPhoneError("Please select phone number.");
        } else if (phoneValue != "" && phoneValue.length < 6) {
            setPhoneError("Phone must be at least 6 characters");
            return false;
        } else if (phoneValue.length > 16) {
            setPhoneError("Phone must not exceed 16 characters");
            return false;
        } else {
            delete errors?.phone;
            setPhoneError("");
            return true;
        }
    }

      var  settings  = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: true,
        };


    // Initializing select2 here
    useEffect(() => {
        if (countryCode?.length > 0) {
            window.$("#single-county-code").select2({
                theme: "bootstrap-5",
                width: window.$(this).data("width")
                    ? window.$(this).data("width")
                    : window.$(this).hasClass("w-100")
                        ? "100%"
                        : "style",
                placeholder: window.$(this).data("placeholder"),
            });

            window.$("#single-county-code").on("change", function (e) {
                setPhoneCode(e.target.value);
            });
        }
    }, [countryCode, phoneError]);


return (
    <section className="first-section "  ref={props?.formRef} style={{ backgroundImage: 'url("/assets/img/webinar/webinar-Desktop.png")' }}
    >
        {/* <img
                src=""
                className=" purple-img d-none d-md-block"
            />
            <img
                src="/assets/img/webinar/webinar-mobile.png"
                className=" purple-img d-block d-md-none"
            /> */}
        <div className="main-container">
            <div className="row sub-register">
                <div className="learning-div col-lg-8">
                    <img
                        src="/assets/img/webinar/stock.png"
                        className="d-none d-md-block"
                    />
                    <div className="learn-play-guitar">
                        <h2>{props?.webinarData[0]?.topic}</h2>
                        <div className="time-section">
                            <p>
                                <img src="/assets/img/webinar/calendar-2.png" />
                                {props?.webinarData[0]?.date}
                            </p>
                            <p>
                                <img src="/assets/img/webinar/clock.png" />
                                {`${props?.webinarData[0]?.time_start.replace(
                                    "PM",
                                    ""
                                )}-${props?.webinarData[0]?.time_end} IST`}
                            </p>
                            <p>
                                {" "}
                                <img src="/assets/img/webinar/location.png" />
                                Online{" "}
                                <img
                                    className="zoom-img"
                                    src="/assets/img/webinar/zoom.png"
                                />
                            </p>
                        </div>
                    </div>
                </div>
                <div className="register-div col-lg-4 col-md-12 position-relative">
                    <div className="floating-images">
                        <img
                            src="/assets/img/webinar/guitar-image.png"
                            alt="Instructor 1"
                            className="floating-img top-left"
                        />
                        <img
                            src="/assets/img/webinar/small-guitar-image.png"
                            alt="Instructor 2"
                            className="floating-img top-right"
                        />
                    </div>
                    <div className="form-area" style={{width: '340px', height: '460px'}}>
                        <div className="card">
                            <div className="heading">
                                <h2>Register Now</h2>
                            </div>
                            <form
                                className="form-section"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <div className="form-group">
                                    <div className="input-div">
                                        <label>Name</label>
                                        <div className="d-flex gap-2 mt-1">
                                            <FormInput
                                                id="name"
                                                ref={props?.nameInputRef}
                                                type="text"
                                                identity="name"
                                                name="name"
                                                autoComplete="off"
                                                placeholder="Enter your name"
                                                register={register("name")}
                                                // error={errors?.name}
                                                className="form name-email"
                                                defaultValue={
                                                    authState?.hash
                                                        ? authState?.name
                                                        : ""
                                                }
                                                // value={nameValue}
                                                // onChange={(e) => {
                                                //     validate(e);
                                                // }}
                                            />
                                        </div>
                                        {errors?.name?.message && (
                                            <p className="incorrect_msg">
                                                <img
                                                    src="assets/img/error.svg"
                                                    alt=""
                                                />{" "}
                                                {errors.name.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="input-div">
                                        <label>Email address</label>
                                        <div className="d-flex gap-2 mt-1">
                                            <FormInput
                                                id="email"
                                                type="text"
                                                identity="email"
                                                name="email"
                                                autoComplete="off"
                                                placeholder="Enter your email Address"
                                                register={register("email")}
                                                // error={errors?.email}
                                                className="form name-email"
                                                defaultValue={
                                                    authState?.hash
                                                        ? authState?.email
                                                        : ""
                                                }
                                            />
                                        </div>
                                        {errors?.email?.message && (
                                            <p className="incorrect_msg">
                                                <img
                                                    src="assets/img/error.svg"
                                                    alt=""
                                                />{" "}
                                                {errors.email.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="input-div">
                                        <label>WhatsApp number</label>
                                        <div className="d-flex gap-2 mt-1">
                                            <div className="phone-code">
                                                <select
                                                    className="form-select"
                                                    id="single-county-code"
                                                    data-placeholder="Choose"
                                                    name="country_code"
                                                    {...register("phone_code")}
                                                    value={phoneCode}
                                                    defaultValue={
                                                        getUserData?.user_data
                                                            ?.phone
                                                    }
                                                    onChange={(e) =>
                                                        setPhoneCode(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="91">
                                                        +91
                                                    </option>
                                                    {filteredCountryOptions?.length >
                                                        0 &&
                                                        filteredCountryOptions.map(
                                                            (countryData) => {
                                                                return (
                                                                    <option
                                                                        key={
                                                                            countryData?.phoneCode
                                                                        }
                                                                        value={
                                                                            countryData?.phoneCode
                                                                        }
                                                                    >
                                                                        +
                                                                        {
                                                                            countryData?.phoneCode
                                                                        }
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                </select>
                                            </div>

                                            <FormInput
                                                id="phoneNumber"
                                                type="text"
                                                identity="phone"
                                                name="phone_number"
                                                autoComplete="off"
                                                placeholder="Enter your whatsapp number"
                                                register={register(
                                                    "phone_number"
                                                )}
                                                className="form"
                                                onChange={handlePhoneChange}
                                                // value={phone}
                                                defaultValue={
                                                    getUserData?.user_data
                                                        ?.phone
                                                }
                                            />
                                        </div>
                                        {!phoneError &&
                                            errors?.phone?.message && (
                                                <p className="incorrect_msg">
                                                    <img
                                                        src="assets/img/error.svg"
                                                        alt=""
                                                    />{" "}
                                                    {
                                                        errors.phone_number
                                                            .message
                                                    }
                                                </p>
                                            )}
                                        {!phoneError &&
                                            errors?.country_code?.message && (
                                                <p className="incorrect_msg">
                                                    <img
                                                        src="assets/img/error.svg"
                                                        alt=""
                                                    />{" "}
                                                    {
                                                        errors.country_code
                                                            .message
                                                    }
                                                </p>
                                            )}
                                        {phoneError && (
                                            <p className="incorrect_msg">
                                                <img
                                                    src="assets/img/error.svg"
                                                    alt=""
                                                />{" "}
                                                {phoneError}
                                            </p>
                                        )}

                                        {/* {showHintText('phone')} */}
                                    </div>
                                </div>
                                {/* <div className="form-check d-none d-md-block">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="newsletter"
                                        checked={receiveUpdates}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="newsletter"
                                    >
                                        I want to join TORRINS WhatsApp
                                        community to receive updates
                                    </label>
                                </div> */}
                                <div class="submit-btn">
                                    {saveWebinarLoading ? (
                                        <button class="btn action-btn" disabled>
                                            Loding...
                                        </button>
                                    ) : (
                                        <button class="btn action-btn">
                                            Join for Free
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="active-learners-section">
                    <div class="learners-images">
                        <img
                            src="/assets/img/webinar/instructor1.png"
                            alt="Learner 1"
                        />
                        <img
                            src="/assets/img/webinar/instructor2.png"
                            alt="Learner 2"
                        />
                        <img
                            src="/assets/img/webinar/instructor3.png"
                            alt="Learner 3"
                        />
                    </div>
                    <div class="learner-info">
                        <h1>{props?.activeUsersCount}</h1>
                        <p>Active learners</p>
                    </div>
                </div>
                <div className="webinar-will-learn">
                    <h3>In this webinar you will learn</h3>
                    <ol>
                        {props?.webinarData[0]?.to_learn?.length > 0
                            ? props?.webinarData[0]?.to_learn.map((learn) => {
                                return <li>{learn}</li>;
                            })
                            : ""}
                    </ol>
                    <div className="join-free">
                        <div
                            class="submit-btn join-free-btn"
                            style={{ marginTop: "22px" }}
                        >
                            <button class="btn action-btn" onClick={props?.handleJoinClick}>
                                Join for Free
                            </button>
                        </div>
                        {/* <img
                            src="/assets/img/webinar/big-guitar-img.png"
                            alt=""
                        /> */}
                    </div>
                </div>
            </div>
            <div className="join-this-webinar">
                <h1>Join this webinar if you are</h1>
                {/* <Slider {...settings} className="join_slider"> */}
                    <div className="slick-join-section">
                        {props?.webinarData[0]?.why_join?.length > 0
                            ? props?.webinarData[0]?.why_join.map((joinContent) => {
                                return (
                                    <div className="join-img">
                                        <div className="img-container">
                                            <img
                                                className="desktop-join d-none d-md-block"
                                                src="/assets/img/webinar/desktop-join.png"
                                            />
                                            <img
                                                className="mobile-join d-block d-md-none"
                                                src="/assets/img/webinar/mobile-join.png"
                                            />
                                            <img
                                                className="star"
                                                src="/assets/img/bg-star.svg"
                                            />
                                            <h3>
                                                {joinContent}
                                            </h3>
                                        </div>
                                    </div>
                                );
                            })
                            : ""}
                    </div>
                {/* </Slider> */}
            </div>
        </div>
    </section>
);
};

export default LearnWebinarSection;
