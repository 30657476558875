import { faqConstants } from "../Constants/FaqConstants";

const initialState = {
    error: {},
    contactFlag: false,
    faqData:{},
    faqCategoryData:{},
    success:false,
    countryCode: [],
};

export const faqReducer = (state = initialState, action) => {
    switch (action.type) {
        case faqConstants.CONTACT_REQUEST:
            return {
                ...state,
                contactFlag: true,
                success: false
            };
            break;

        case faqConstants.CONTACT_SUCCESS:
            return {
                ...state,
                contactFlag: false,
                success: true
            };
            break;

        case faqConstants.CONTACT_FAILURE:
            return {
                ...state,
                contactFlag: false,
                success: false
            };
            break;

        case faqConstants.FAQ_CATEGORY_PENDING:
            return {
                ...state,
                loading: true
            };
            break;

        case faqConstants.FAQ_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                faqCategoryData: action.payload,
                activeCategory: action.payload?.categories[0]
            };
            break;

        case faqConstants.FAQ_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                faqCategoryData:[]
                
            };
            break;

        case faqConstants.FAQ_PENDING:
            return {
                ...state,
                loading: true
            };
            break;

        case faqConstants.FAQ_SUCCESS:
            return {
                ...state,
                loading: false,
                faqData: action.payload,
            };
            break;

        case faqConstants.FAQ_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                faqData:[]
            };
            break;

        case 'SET_ACTIVE_MENU':
            return { 
                ...state, 
                activeMenu: action.payload,
                error: {"type" : "active", "message" : action?.payload?.message} 
            };
            break;
            
        case 'INITIAL':
            return { 
                ...state, 
                success: false 
            };
            break;

            case faqConstants.COUNTRY_CODE_PENDING:
            return {
                ...state,
                loading: true,
            };
            break;

        case faqConstants.COUNTRY_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
                countryCode: action.payload,
            };
            break;

        case faqConstants.COUNTRY_CODE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                countryCode: []
            };
            break;

        default:
            return state;
            break;
    }
};
