import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const BobTicker = ({ setIsBobShow, isBobShow, isModalActive }) => {
  const allMessages = [
    {
      text: "Check out our band competition ",
      url: "/battle-of-bands",
      external: false,
    },
    // {
    //   text: "Black Friday Sale:",
    //   url: "https://www.torrins.com/membership",
    //   external: true,
    // },
  ];

  const filteredMessages = allMessages.filter(
    (msg) => !(msg.url === "/battle-of-bands" && window.location.pathname.includes('/battle-of-bands'))
  );

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    if (filteredMessages?.length === 0) return;
    const interval = setInterval(() => {
      setIsFading(true);
      setTimeout(() => {
        setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % filteredMessages.length);
        setIsFading(false);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, [filteredMessages]);

  if (filteredMessages?.length === 0) return null;

  const { text, url, external } = filteredMessages?.length > 0 && filteredMessages[currentMessageIndex];

  return (
    <div
      className={`bobticker ${isBobShow ? "show" : ""}`}
      style={{ zIndex: isModalActive ? '9999' : '1' }}
    >
      <p className={`slide ${isFading ? "out" : "in"}`}>
        {text}
        {external ? (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {" "} Get 40% Off on Annual Memberships
          </a>
        ) : (
          <Link to={url}>Battle of the Bands</Link>
        )}
      </p>
    </div>
  );
};
