import React, { useEffect, useState } from 'react'
import SideBar from '../../../components/Common/SideBar'
import '../../../components/Common/common.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './/MembershipPlan.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import BreadCrumbs from '../../../components/Common/BreadCrumbs';
import breadcrumbs from '../../../components/BreadCrumbs/breadcrumbsObj';
import { authService } from '../../../Adapters/auth.services';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardAction } from '../../../Action/User/dashboard.actions';
import RecomendedCourse from './RecomendedCourse';


const MembershipPlanCancel = () => {
    const dispatch = useDispatch();
    const currentUrl = useLocation();
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const socialToken = authState?.userDetails?.token;
    const token = authState.userDetails.hash;

    const dashboardData = useSelector((state) => state.dashboard);
    const [achieveMentData, setAchieveMentData] = useState([])
    const [recomendedData, setRecomendedData] = useState([])
    const [isHovered, setIsHovered] = useState(false);


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        dispatch(dashboardAction.lessonHistory({ filter: 'lessons' }, { token }));
        getAllData()

    }, [])

    const getAllData = async () => {
        try {

            const data = await authService.getDataWithToken(`/api/get-user-achievements`, { token: token || socialToken });
            const recomendedDatas = await authService.getDataWithToken(`/api/recommended-courses`, { token: token || socialToken });


            if (data?.status === 200) {
                setAchieveMentData(data?.data?.data)

            } else {
            }
            if (recomendedDatas?.status === 200) {
                setRecomendedData(recomendedDatas?.data?.data?.lessons)
            }
        } catch (err) {
            console.error('Error:', err);
        } finally {
        }
    };



    const parseDurationTime = (durationString) => {
        if (durationString) {
            const [hours, minutes, seconds] = durationString?.split(":").map(Number);

            let message = '';

            if (hours > 1) {
                message = `${hours} hrs remaining`;
            } else if (hours == 1) {
                message = `${hours} hr remaining`
            } else if (minutes > 0) {
                message = `${minutes} mins remaining`;
            } else if (seconds > 0) {
                message = `${seconds} secs remaining`;
            } else {
                message = ``;
            }
            return message
        }

    }


    function parseTimeToSeconds(timeString) {
        const parts = timeString?.split(':');
        const hours = parseInt(parts[0]);
        const minutes = parseInt(parts[1]);
        const seconds = parseInt(parts[2]);

        return hours * 3600 + minutes * 60 + seconds;
    }


    const handleGoBack = () => {
        navigate('/membership-settings');
    };

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const sidebarClass = isHovered ? "col-md-3" : "col-md-1";
    const sidebarViewClass = isHovered ? "9" : "11";

    return (
        <div className='authenticate cancel-membership-page'>

            <BreadCrumbs data={breadcrumbs.membershipPlansCancel} currentPage={currentUrl.pathname} />

            <div className="row  px-0">
                <SideBar 
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                    sidebarClass={sidebarClass}
                    isHovered={isHovered}
                />

                <div className={`right-section col-xxl-${sidebarViewClass} col-xl-${sidebarViewClass} col-lg-${sidebarViewClass} col-lg-${sidebarViewClass} col-md-${sidebarViewClass} col-sm-12 membership-setting membership-settings-section`}>
                    <div className=" membership-cancel-subscription ">
                        <div className="  bg-white  gap-2 mt-2  membership-cancel-subscription-container mb-4">
                            <div className="d-flex align-items-center justify-content-start gap-2">
                                <img src="/assets/img/back-arrow.png" style={{ cursor: 'pointer' }} onClick={handleGoBack} />
                                <h4 className="p-0 m-0">Cancel my membership</h4>
                            </div>
                            {dashboardData?.historyLoading ?
                                <div style={{ padding: '20%' }}>
                                    <div
                                        className="loader spinner-border m-5 d-table m-auto"
                                        role="status"
                                    >
                                        <span className="visually-hidden"></span>
                                    </div>
                                    <span className=" m-5 d-table m-auto">
                                        Loading...
                                    </span>
                                </div>
                                :

                                <>
                                    <div className="header-about-info mt-3 ">
                                        <div className="d-flex flex-column">
                                            <p>Hey {authState?.userProfile?.user_data?.name || authState?.userDetails?.name}, learning an instrument takes time.</p>
                                            <h3> But Kudos to your hard work you have come a long way.</h3>
                                        </div>
                                        <div className="d-flex gap-3">
                                            <div className="module-completed">
                                                <div className="time">Courses Completed</div>
                                                <div className="content">{achieveMentData?.course_completed}</div>
                                            </div>
                                            <div className="module-completed">
                                                <div className="time">Songs Learned</div>
                                                <div className="content">{achieveMentData?.songs_learned}</div>
                                            </div>
                                        </div>
                                    </div>


                                    {dashboardData?.lessonHistory?.lessons?.length > 0 &&

                                        <div className="cancel-course-content my-4 flex-column">
                                            <h3>Let's continue your courses</h3>

                                            <Swiper
                                                modules={[Pagination, Navigation]}
                                                slidesPerView={2}
                                                spaceBetween={10}
                                                grabCursor={true}
                                                loop={true}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                                navigation={{
                                                    nextEl: '.button-next',
                                                    prevEl: '.button-prev',
                                                }}
                                                breakpoints={{
                                                    320: {
                                                        slidesPerView: 1,
                                                        spaceBetween: 20,
                                                    },
                                                    480: {
                                                        slidesPerView: 1,
                                                        spaceBetween: 30,
                                                    },
                                                    640: {
                                                        slidesPerView: 1,
                                                        spaceBetween: 40,
                                                    },
                                                    940: {
                                                        slidesPerView: 1,
                                                        spaceBetween: 40,
                                                    },
                                                    1200: {
                                                        slidesPerView: 3,
                                                        spaceBetween: 40,
                                                    },
                                                    1366: {
                                                        slidesPerView: 3,
                                                        spaceBetween: 40,
                                                    },
                                                    2000: {
                                                        slidesPerView: 3,
                                                        spaceBetween: 40,
                                                    },
                                                }}
                                                className="carousel"
                                            >
                                                {dashboardData?.lessonHistory?.lessons ? (
                                                    dashboardData?.lessonHistory?.lessons?.slice(0, 10)?.map((historyData, index) => {
                                                        const parent = historyData?.parent?.length ? historyData.parent[0] : {};
                                                        const courseUrl = `/${historyData?.path?.[historyData.path.length - 1]?.hash}/${historyData?.hash}-${historyData?.id}`;
                                                        const progressWidth = `${historyData?.completion_progress}%`;

                                                        return (
                                                            <SwiperSlide
                                                                key={index}
                                                                className="d-block d-lg-flex d-md-flex bg-white p-3 course-card cancel-course-card align-items-start justify-content-between"
                                                            >
                                                                <div className="d-flex align-items-center gap-3 flex">
                                                                    <div className="container-course-img">
                                                                        <Link to={courseUrl}>
                                                                            <img alt="poster" className="video" src={historyData?.poster} />
                                                                            <img alt="play" className="play-icon" src="/assets/img/playicon.svg" />
                                                                        </Link>
                                                                    </div>
                                                                    <div className="second-card">
                                                                        <h6 className="cut-text">{parent?.title}</h6>
                                                                        <p className="size-12 mb-1 cut-text">
                                                                            {historyData?.position}/{parent?.total} modules completed
                                                                        </p>
                                                                        <span className="size-12">Lesson {historyData?.position}</span>
                                                                        <Link to={courseUrl}>
                                                                            <h5 className="cut-text-learning">{historyData?.title}</h5>
                                                                        </Link>
                                                                        <div className="custom-progress-bar">
                                                                            <div className="custom-progress-compeleted" style={{ width: progressWidth }}></div>
                                                                        </div>
                                                                        {parseTimeToSeconds(historyData?.remaining_duration) !== 0 && (
                                                                            <p className="custom-badge">
                                                                                <img alt="Clock Icon" src="/assets/img/clock.png" />
                                                                                {parseDurationTime(historyData?.remaining_duration)}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        );
                                                    })
                                                ) : null}

                                                <div className="button-prev">
                                                    <img src="/assets/img/learning/left-arrow.svg" alt="Previous" />
                                                </div>
                                                <div className="button-next">
                                                    <img src="/assets/img/learning/right-arrow.svg" alt="Next" />
                                                </div>
                                            </Swiper>




                                        </div>
                                    }

                                    <div>
                                      {recomendedData?.length > 0 &&  <RecomendedCourse recomendedData={recomendedData} /> }
                                        <div className="d-flex align-items-center cancel-info-section">
                                            <img height="auto" src="/assets/img/MembershipSettings/info.svg" alt="" />
                                            <p>You will lose all membership benefits if you cancel</p>
                                        </div>
                                        <div className="d-flex align-items-center cancel-info-section">
                                            <img height="auto" src="/assets/img/MembershipSettings/info.svg" alt="" />
                                            <p>Come back within 60 days and your learnings and rewards will be waiting </p>
                                        </div>

                                        <div className="d-flow text-start mt-3">
                                            <Link to={'/guitar-lessons'} className="btn btn-subscribe me-2">Continue learning</Link>
                                            <Link className="btn btn-cancel justify-content-start" to={'/membership/cancel/reasons'}> My new year resolution can
                                                wait, cancel my subscription</Link>

                                        </div>


                                    </div>

                                </>



                            }


                        </div>


                    </div>

                </div>
            </div>

        </div >

    )
}

export default MembershipPlanCancel