import React, { useState } from 'react'

const NarayanaFaq = ({bobData}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const handleAccordionClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div className='faq-narayana'>
            <h3>Answers to common questions</h3>
            <div className="frequently-asked-section">
                {bobData?.faq?.data?.map((item, index) => (
                    <div key={index} className='contents'>
                        <button
                            className={`accordion ${index === activeIndex ? 'active' : ''}`}
                            onClick={() => handleAccordionClick(index)}
                        >{index + 1}. {''}{item?.question}</button>
                        <div className="panel" style={{
                            maxHeight: index === activeIndex ? '100%' : '0',
                            overflow: 'hidden',
                            transition: 'max-height 0.3s ease'
                        }}>

                            <p>{item?.answer}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default NarayanaFaq