import { lessonService } from "../Adapters/lessonSongs.services";
import { lessonConstants } from "../Constants/LessonSongs";
import { toast } from "react-toastify";
import CustomSuccessToast from "../components/CustomToaster/CustomSuccessToaster";
import CustomErrorToast from "../components/CustomToaster/CustomErrorToast";

export const lessonSongs = {
    getLessonsData,
    getLessonHistory,
    getPlaylistData,
    savePlaylist,
    getTopCategory,
    getTopCategoryData,
    getMainCategoryData,
    // getTopicData,
    // getTopPickData,
    getKnowMoreData,
    getCourseDetailById,
    getRecommendedCourses,
    fetchAllCourses,
    getLessonHierarcy,
    fetchAllMainCourses,
    getShowMembershipView,
    getCourseCompletionProgress,
    getSongsCount,
    savePopUp,
    getAssessmentProgress,
    getSimilarCourses,
    getSongUserCount,
    getSearch,
    recentSearches,
    savedSearches,
    getOtherData,
    getRecentWatchedChapter,
    getLevel,
    getCourseDetailByIdExtras,
    getKnowMoreDataExtras,
    getLessonsExtras
}

async function handleRequest(endpoint, params, successType, failureType, headers = null) {
    const response = await lessonService.sendTokenInHeader(endpoint, params, headers)
        
    if (response?.status === 200 && response?.data?.code === "SUCCESS") {
        if (response.data.message) {
            toast(<CustomSuccessToast message={response.data.message} />);
        }
        return { type: successType, payload: response?.data };
    } else if (response?.status === 201 && response?.data?.code === "SUCCESS") {
        toast(<CustomSuccessToast message={response?.data?.message} />)
        return { type: successType, payload: response?.data };
    } else if (response?.status === 404 && response?.data?.code === "ERROR") {
        if (response?.data?.errors) {
            for (const key in response.data.errors) {
                if (response.data.errors.hasOwnProperty(key)) {
                    response.data.errors[key].forEach((errorMessage) => {
                        toast(<CustomErrorToast message={errorMessage} />)
                    });
                }
            }
        } else {
            toast(<CustomErrorToast message={response?.data?.message} />)
        }
        return { type: failureType, payload: response?.data };
    } else if (response?.status === 400 && response?.data?.code === "ERROR") {
        if (response?.data?.errors) {
            for (const key in response.data.errors) {
                if (response.data.errors.hasOwnProperty(key)) {
                    response.data.errors[key].forEach((errorMessage) => {
                        toast(<CustomErrorToast message={errorMessage} />)
                    });
                }
            }
        } else {
            toast(<CustomErrorToast message={response?.data?.message} />)
        }
        return { type: failureType, payload: response?.data };
    } else if (response?.status === 401 && response?.data?.code === "ERROR") {
        if (response?.data?.errors) {
            for (const key in response.data.errors) {
                if (response.data.errors.hasOwnProperty(key)) {
                    response.data.errors[key].forEach((errorMessage) => {
                        toast(<CustomErrorToast message={errorMessage} />)
                    });
                }
            }
        } else {
            toast(<CustomErrorToast message={response?.data?.message} />)
        }
        return { type: failureType, payload: response?.data };
    } else if (response?.status === 500 ) {
        toast(<CustomErrorToast message={'Internal server error'} />)
        return { type: failureType, payload: response?.data };
    } else {
        toast(<CustomErrorToast message={'Some error occured'} />)
        return { type: failureType, payload: response?.data };
    }
}

async function savePlaylist(params, headers) {
    return handleRequest("/api/add-playlist", params,  lessonConstants.SAVE_PLAYLIST_SUCCESS, lessonConstants.SAVE_PLAYLIST_FAILURE, headers);
   
}

async function savePopUp(params, headers) {
    return handleRequest("/api/saved-popup-details", params,  lessonConstants.SAVE_POP_UP_SUCCESS, lessonConstants.SAVE_POP_UP_FAILURE, headers);
   
}

function getLessonsData(params, headers) {
    let parent = (params) ? `?filter[level]=${params}` : '';

    return (dispatch) => {
        dispatch(request());
        lessonService
        .getLessonsData(`/api/lessons${parent}`, headers)
        .then((response) => {
            dispatch(success({courses: response?.data?.data, filters:response?.data}));
        })
        .catch((error) => {
            dispatch(errorLessonSongs(error));
        });
    };
  
    function errorLessonSongs(error) {
        return { type: lessonConstants.GET_LESSONSONGS_FAILURE, error: error };
    }
  
    function request() {
        return { type: lessonConstants.GET_LESSONSONGS_PENDING };
    }
  
    function success(data) {
        return { type: lessonConstants.GET_LESSONSONGS_SUCCESS, payload: data };
    }
}

function fetchAllCourses(params) {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/all-courses`);
    for (let key in params) {
        url.searchParams.append(key, params[key]);
    }

    return (dispatch) => {
        dispatch(request());
        lessonService
        .fetchAllCourses(url.href)
        .then((response) => {
            dispatch(success({courses: response?.data?.data, filters:response?.data}));
        })
        .catch((error) => {
            dispatch(errorLessonSongs(error));
        });
    };
  
    function errorLessonSongs(error) {
        return { type: lessonConstants.GET_LESSONSONGS_FAILURE, error: error };
    }
  
    function request() {
        return { type: lessonConstants.GET_LESSONSONGS_PENDING };
    }
  
    function success(data) {
        return { type: lessonConstants.GET_LESSONSONGS_SUCCESS, payload: data };
    }
}

async function fetchAllMainCourses() {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/all-courses`);

    return await lessonService.fetchAllCourses(url.href)
}

function getLessonHistory( params, headers) {
    return (dispatch) => {
        dispatch(request());
        lessonService
        .getLessonHistory(`/api/lesson-history?completed=${params}`, headers)
        .then((response) => {
            dispatch(success(response?.data));
        })
        .catch((error) => {
            dispatch(errorLessonHistory(error));
        });
    };
  
    function errorLessonHistory(error) {
        return { type: lessonConstants.GET_LESSONHISTORY_FAILURE, error: error };
    }
  
    function request() {
        return { type: lessonConstants.GET_LESSONHISTORY_PENDING };
    }
  
    function success(data) {
        return { type: lessonConstants.GET_LESSONHISTORY_SUCCESS, payload: data };
    }
}

function getPlaylistData(id, params, headers) {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/playlist/${id}`);
    for (let key in params) {
        url.searchParams.append(key, params[key]);
    }
    
    return (dispatch) => {
        dispatch(request());
        lessonService
        .getPlaylistData(url.href, headers)
        .then((response) => {
            dispatch(success(response.data.data));
        })
        .catch((error) => {
            dispatch(errorPlaylist(error));
        });
    };
  
    function errorPlaylist(error) {
        return { type: lessonConstants.GET_PLAYLIST_FAILURE, error: error };
    }
  
    function request() {
        return { type: lessonConstants.GET_PLAYLIST_PENDING };
    }
  
    function success(data) {
        return { type: lessonConstants.GET_PLAYLIST_SUCCESS, payload: data };
    }
}

function getTopCategory(params, filterType, headers) {
    let parent = (params) ? `?parent=${params}` : '';

    if (filterType) {
        parent += `&filter[top_course]=${filterType}`;
    }

    return (dispatch) => {
        dispatch(request());
        lessonService
        .getLessonsData(`/api/lessons${parent}`, headers)
        .then((response) => {
            dispatch(success(response?.data));
        })
        .catch((error) => {
            dispatch(errorLessonCategory(error));
        });
    };
  
    function errorLessonCategory(error) {
        return { type: lessonConstants.GET_LESSON_CATEGORY_FAILURE, error: error };
    }
  
    function request() {
        return { type: lessonConstants.GET_LESSON_CATEGORY_PENDING };
    }
  
    function success(data) {
        return { type: lessonConstants.GET_LESSON_CATEGORY_SUCCESS, payload: data };
    }
}

function getTopCategoryData(params, headers ) {
    let parent = (params) ? `?parent=${params}` : '';
    
    return (dispatch) => {
        dispatch(request());
        lessonService
        .getLessonsData(`/api/lessons${parent}`, headers)
        .then((response) => {
            dispatch(success(response?.data?.data));
        })
        .catch((error) => {
            dispatch(errorLessonCategory(error));
        });
    };
  
    function errorLessonCategory(error) {
        return { type: lessonConstants.GET_TOP_CATEGORY_FAILURE, error: error };
    }
  
    function request() {
        return { type: lessonConstants.GET_TOP_CATEGORY_PENDING };
    }
  
    function success(data) {
        return { type: lessonConstants.GET_TOP_CATEGORY_SUCCESS, payload: data };
    }
}

function getMainCategoryData(params, filterType, headers={}) {
    let parent = (params) ? `?parent=${params}` : '';

    if (filterType?.difficulty) {
        parent += `&difficulty=${filterType?.difficulty}`;
    }
    if (filterType?.filterType) {
        parent += `&filter[free]=${filterType?.filterType}&filter[level]=2`;
    }
    if (filterType?.instructor) {
        parent += `${params ? '&' : ''}instructor=${filterType?.instructor}`;
    }

    if (filterType?.sort_type) {
        parent += `&sort=${filterType?.sort_type}`;
    }

    if (filterType?.level) {
        parent += `&filter[level]=${filterType?.level}`;
    }

    if (filterType?.free) {
        parent += `&filter[free]=${filterType?.free}`;
    }

    // Pagination 
    if (filterType?.page) {
        parent += `&page=${filterType?.page}`;
    }

    return (dispatch) => {
        dispatch(request());
        lessonService
            .getLessonsData(`/api/lessons${parent}`, headers)
            .then((response) => {
                const mainCategory = {mainCategory: response?.data?.data ?? [], pagination: response.data?.pagination, total: response?.data?.total, category: response?.data?.filters};
                dispatch(success( mainCategory));
            })
            .catch((error) => {
                dispatch(errorMainCategory(error));
            });
    };
    
  
    function errorMainCategory(error) {
        return { type: lessonConstants.GET_MAIN_CATEGORY_FAILURE, error: error };
    }
  
    function request() {
        return { type: lessonConstants.GET_MAIN_CATEGORY_PENDING };
    }
  
    function success(data) {
        return { type: lessonConstants.GET_MAIN_CATEGORY_SUCCESS, payload: data };
    }
}

function getLessonsExtras(params, filterType, headers={}) {
    let parent = (params) ? `?parent=${params}` : '';

    if (filterType?.difficulty) {
        parent += `&difficulty=${filterType?.difficulty}`;
    }
    if (filterType?.filterType) {
        parent += `&filter[free]=${filterType?.filterType}&filter[level]=2`;
    }
    if (filterType?.instructor) {
        parent += `${params ? '&' : ''}instructor=${filterType?.instructor}`;
    }

    if (filterType?.sort_type) {
        parent += `&sort=${filterType?.sort_type}`;
    }

    if (filterType?.level) {
        parent += `&filter[level]=${filterType?.level}`;
    }

    if (filterType?.free) {
        parent += `&filter[free]=${filterType?.free}`;
    }

    // Pagination 
    if (filterType?.page) {
        parent += `&page=${filterType?.page}`;
    }

    return (dispatch) => {
        dispatch(request());
        lessonService
            .getLessonsData(`/api/lessons-extras${parent}`, headers)
            .then((response) => {
                const mainCategory = {mainCategoryExtras: response?.data?.data ?? [], pagination: response.data?.pagination, total: response?.data?.total, category: response?.data?.filters};
                dispatch(success( mainCategory));
            })
            .catch((error) => {
                dispatch(errorLessonCategory(error));
            });
    };
    
  
    function errorLessonCategory(error) {
        return { type: lessonConstants.GET_LESSON_EXTRAS_FAILURE, error: error };
    }
  
    function request() {
        return { type: lessonConstants.GET_LESSON_EXTRAS_PENDING };
    }
  
    function success(data) {
        return { type: lessonConstants.GET_LESSON_EXTRAS_SUCCESS, payload: data };
    }
}

// function getTopicData(params, filterType, headers) {
   
//     let parent = (params) ? `?parent=${params}` : '';

//     if (filterType) {
//         parent += `&filter[hash]=${filterType}`;
//     }

//     return (dispatch) => {
//         dispatch(request());
//         lessonService
//         .getLessonsData(`/api/lessons${parent}`, headers)
//         .then((response) => {
//             dispatch(success(response?.data?.data));
//         })
//         .catch((error) => {
//             dispatch(errorLessonCategory(error));
//         });
//     };
  
//     function errorLessonCategory(error) {
//         return { type: lessonConstants.GET_TOPIC_FAILURE, error: error };
//     }
  
//     function request() {
//         return { type: lessonConstants.GET_TOPIC_PENDING };
//     }
  
//     function success(data) {
//         return { type: lessonConstants.GET_TOPIC_SUCCESS, payload: data };
//     }
// }

// function getTopPickData(params, filterType, headers) {
//     let parent = (params) ? `?parent=${params}` : '';

//     if (filterType) {
//         parent += `&filter[trending]=${filterType}`;
//     }

//     return (dispatch) => {
//         dispatch(request());
//         lessonService
//         .getLessonsData(`/api/lessons${parent}`, headers)
//         .then((response) => {
//             dispatch(success(response?.data?.data));
//         })
//         .catch((error) => {
//             dispatch(errorLessonToppick(error));
//         });
//     };
  
//     function errorLessonToppick(error) {
//         return { type: lessonConstants.GET_TOP_PICK_FAILURE, error: error };
//     }
  
//     function request() {
//         return { type: lessonConstants.GET_TOP_PICK_PENDING };
//     }
  
//     function success(data) {
//         return { type: lessonConstants.GET_TOP_PICK_SUCCESS, payload: data };
//     }
// }

function getKnowMoreData(params, headers, isPersonalization = '', personlozationLessionId = '') {
    let url = isPersonalization === 'course' ? `/api/get-personalization/${personlozationLessionId}?child=${params}` : `/api/lessons-hierarchy/${params}`;

    return (dispatch) => {
        dispatch(request());
        lessonService
        .getLessonsData(url, headers)
        .then((response) => {
            let levelsData = {}
            if (response?.data?.data?.type === "songs") {
                const songData = { lessons: [response?.data?.data] }
                levelsData = {knowMoreData: songData}
            } else {
                levelsData = {knowMoreData:response?.data?.data, nextLevelId:response?.data }
            }
            
            dispatch(success(levelsData));
        })
        .catch((error) => {
            dispatch(errorKnowMore(error));
        });
    };
  
    function errorKnowMore(error) {
        return { type: lessonConstants.GET_KNOW_MORE_FAILURE, error: error };
    }
  
    function request() {
        return { type: lessonConstants.GET_KNOW_MORE_PENDING };
    }
  
    function success(data) {
        return { type: lessonConstants.GET_KNOW_MORE_SUCCESS, payload: data };
    }
}

function getKnowMoreDataExtras(params, headers, flag ='',isPersonalization = '', personlozationLessionId = '') {
    let url = isPersonalization === 'course' ? `/api/get-personalization/${personlozationLessionId}?child=${params}` : `/api/lesson/${params}`;

    return (dispatch) => {
        dispatch(request(flag));
        lessonService
        .getLessonsData(url, headers)
        .then((response) => {
            dispatch(success(response?.data?.data, flag));
        })
        .catch((error) => {
            dispatch(errorKnowMore(error, flag));
        });
    };
  
    function errorKnowMore(error) {
        return { type: lessonConstants.GET_KNOW_MORE_EXTRAS_FAILURE, error: {error, flag } };
    }
  
    function request() {
        return { type: lessonConstants.GET_KNOW_MORE_EXTRAS_PENDING , payload: { flag }};
    }
  
    function success(data) {
        return { type: lessonConstants.GET_KNOW_MORE_EXTRAS_SUCCESS, payload: { data, flag }  };
    }
}


function getCourseDetailById(params, headers, flag='', isPersonalization = '', lessonId = '') {
    let url = isPersonalization === 'course' ? lessonId ?  `/api/get-personalization/${params}?child=${lessonId}` : `/api/get-personalization/${params}` : `/api/lessons-hierarchy/${params}`;

    return (dispatch) => {
        dispatch(request(flag));
        lessonService
        .getLessonsData(url, headers)
        .then((response) => {
            dispatch(success(response?.data?.data, flag));
        })
        .catch((error) => {
            dispatch(errorKnowMore(error, flag));
        });
    };
  
    function errorKnowMore(error, flag) {
        return { type: 'START_COURSE_FAILURE', error: {error, flag } };
    }
  
    function request(flag) {
        return { type: 'START_COURSE_PENDING', payload: { flag } };
    }
  
    function success(data, flag) {
        return { type: 'START_COURSE_SUCCESS', payload: { data, flag } };
    }
}
function getCourseDetailByIdExtras(params, headers, flag='', isPersonalization = '', lessonId = '') {
    let url = isPersonalization === 'course' ? lessonId ?  `/api/get-personalization/${params}?child=${lessonId}` : `/api/get-personalization/${params}` : `/api/lesson/${params}`;

    return (dispatch) => {
        dispatch(request(flag));
        lessonService
        .getLessonsData(url, headers)
        .then((response) => {
            dispatch(success(response?.data?.data, flag));
        })
        .catch((error) => {
            dispatch(errorKnowMore(error, flag));
        });
    };
  
    function errorKnowMore(error, flag) {
        return { type: 'START_COURSE_EXTRAS_FAILURE', error: {error, flag } };
    }
  
    function request(flag) {
        return { type: 'START_COURSE_EXTRAS_PENDING', payload: { flag } };
    }
  
    function success(data, flag) {
        return { type: 'START_COURSE_EXTRAS_SUCCESS', payload: { data, flag } };
    }
}

function getLessonExtra(params, headers, flag='', isPersonalization = '', lessonId = '') {
   let url= `/api/lesson-extra/${params}`;

    return (dispatch) => {
        dispatch(request(flag));
        lessonService
        .getLessonsData(url, headers)
        .then((response) => {
            dispatch(success(response?.data?.data, flag));
        })
        .catch((error) => {
            dispatch(errorKnowMore(error, flag));
        });
    };
  
    function errorKnowMore(error, flag) {
        return { type: 'START_COURSE_EXTRA_FAILURE', error: {error, flag } };
    }
  
    function request(flag) {
        return { type: 'START_COURSE_EXTRA_PENDING', payload: { flag } };
    }
  
    function success(data, flag) {
        return { type: 'START_COURSE_EXTRA_SUCCESS', payload: { data, flag } };
    }
}

function getRecommendedCourses(params, headers) {
    return (dispatch) => {
        dispatch(request());
        lessonService
        .getRecommendedCourses(`/api/recommended-courses?instrument=${params}`, headers)
        .then((response) => {
            dispatch(success(response?.data?.data));
        })
        .catch((error) => {
            dispatch(errorRecommendedCourses(error));
        });
    };
  
    function errorRecommendedCourses(error) {
        return { type: lessonConstants.GET_RECOMMENDED_COURSES_FAILURE, error: error };
    }
  
    function request() {
        return { type: lessonConstants.GET_RECOMMENDED_COURSES_PENDING };
    }
  
    function success(data) {
        return { type: lessonConstants.GET_RECOMMENDED_COURSES_SUCCESS, payload: data };
    }
}

async function getLessonHierarcy(params, headers,flag='', isPersonalization = '', lessonId = '') {
    let url = isPersonalization === 'course' ? lessonId ?  `/api/get-personalization/${params}?child=${lessonId}` : `/api/get-personalization/${params}` : `/api/lessons-hierarchy/${params}`;
    const response = await  lessonService.getLessonsData(url, headers)
    
    if (response?.status === 200) {
        return { type: lessonConstants.GET_LESSONSHIERARCY_SUCCESS, payload: response?.data?.data };
    } else {
        return { type: lessonConstants.GET_LESSONSHIERARCY_FAILURE, error: response.data?.error };
    }

}
async function getLevel(params, headers,flag='', isPersonalization = '', lessonId = '') {
     let url = isPersonalization === 'course' ? lessonId ?  `/api/get-level-type/${params}?child=${lessonId}?type=personalise` : `/api/get-level-type/${params}?type=personalise` : `api/get-level-type/${params}`

   
    const response = await  lessonService.getLessonsData(url, headers)
    
    if (response?.status === 200) {
        return { type: lessonConstants.GET_LEVEL_TYPE_SUCCESS, payload: response?.data?.data };
    } else {
        return { type: lessonConstants.GET_LEVEL_TYPE_FAILURE, error: response.data?.error };
    }
  
}
function getShowMembershipView(params, headers) {

    return (dispatch) => {
        dispatch(request());
        lessonService
        .getShowMembershipView(`/api/free-lessons-progress?instrumentId=${params}`, headers)
        .then((response) => {
            dispatch(success(response?.data));
        })
        .catch((error) => {
            dispatch(errorLessonSongs(error));
        });
    };
  
    function errorLessonSongs(error) {
        return { type: lessonConstants.GET_MEMBERSHIP_VIEW_FAILURE, error: error };
    }
  
    function request() {
        return { type: lessonConstants.GET_MEMBERSHIP_VIEW_PENDING };
    }
  
    function success(data) {
        return { type: lessonConstants.GET_MEMBERSHIP_VIEW_SUCCESS, payload: data };
    }
}

async function getCourseCompletionProgress(params, headers) {
    const response = await lessonService.getCourseCompletionProgress(`/api/course-completion-progress?lesson_id=${params}`, headers);
    
    if (response?.status === 200) {
        return { type: lessonConstants.GET_COURSE_COMPLETION_PROGRESS_SUCCESS, payload: response.data };
    } else {
        return { type: lessonConstants.GET_COURSE_COMPLETION_PROGRESS_FAILURE };
    }

    // return (dispatch) => {
    //     dispatch(request());
    //     lessonService
    //     .getCourseCompletionProgress(`/api/course-completion-progress?lesson_id=${params}`, headers)
    //     .then((response) => {
    //         dispatch(success(response?.data));
    //     })
    //     .catch((error) => {
    //         dispatch(errorLessonSongs(error));
    //     });
    // };
  
    // function errorLessonSongs(error) {
    //     return { type: lessonConstants.GET_COURSE_COMPLETION_PROGRESS_FAILURE, error: error };
    // }
  
    // function request() {
    //     return { type: lessonConstants.GET_COURSE_COMPLETION_PROGRESS_PENDING };
    // }
  
    // function success(data) {
    //     return { type: lessonConstants.GET_COURSE_COMPLETION_PROGRESS_SUCCESS, payload: data };
    // }
}

async function getSongsCount(headers) {
    const response = await lessonService.getSongsCount(`/api/songs-counting`, headers)
    
    if (response?.status === 200) {
        return { type: lessonConstants.GET_SONGS_COUNT_SUCCESS, payload: response.data };
    } else {
        return { type: lessonConstants.GET_SONGS_COUNT_FAILURE };
    }

    // return (dispatch) => {
    //     dispatch(request());
    //     lessonService
    //     .getSongsCount(`/api/songs-counting`, headers)
    //     .then((response) => {
    //         dispatch(success(response?.data));
    //     })
    //     .catch((error) => {
    //         dispatch(errorLessonSongs(error));
    //     });
    // };
  
    // function errorLessonSongs(error) {
    //     return { type: lessonConstants.GET_SONGS_COUNT_FAILURE, error: error };
    // }
  
    // function request() {
    //     return { type: lessonConstants.GET_SONGS_COUNT_PENDING };
    // }
  
    // function success(data) {
    //     return { type: lessonConstants.GET_SONGS_COUNT_SUCCESS, payload: data };
    // }
}

function getAssessmentProgress(params, headers) {

    return (dispatch) => {
        dispatch(request());
        lessonService
        .getAssessmentProgress(`/api/check-assessment-progress?parent=${params}`, headers)
        .then((response) => {
            dispatch(success(response?.data));
        })
        .catch((error) => {
            dispatch(errorLessonSongs(error));
        });
    };
  
    function errorLessonSongs(error) {
        return { type: lessonConstants.GET_ASSESSMENT_PROGRESS_FAILURE, error: error };
    }
  
    function request() {
        return { type: lessonConstants.GET_ASSESSMENT_PROGRESS_PENDING };
    }
  
    function success(data) {
        return { type: lessonConstants.GET_ASSESSMENT_PROGRESS_SUCCESS, payload: data };
    }
}


function getSimilarCourses(params, headers ) {
    
    return (dispatch) => {
        dispatch(request());
        lessonService
        .getLessonsData(`/api/similar-course/${params}`, headers)
        .then((response) => {
            dispatch(success(response?.data?.data));
        })
        .catch((error) => {
            dispatch(errorSimilarCourse(error));
        });
    };
  
    function errorSimilarCourse(error) {
        return { type: lessonConstants.GET_SIMILAR_COURSE_FAILURE, error: error };
    }
  
    function request() {
        return { type: lessonConstants.GET_SIMILAR_COURSE_PENDING };
    }
  
    function success(data) {
        return { type: lessonConstants.GET_SIMILAR_COURSE_SUCCESS, payload: data };
    }
}

function getSongUserCount(params, headers ) {
    
    return (dispatch) => {
        dispatch(request());
        lessonService
        .getLessonsData(`/api/user-completion-progress?parent=${params}`, headers)
        .then((response) => {
            dispatch(success(response?.data));
        })
        .catch((error) => {
            dispatch(errorSongUserCount(error));
        });
    };
  
    function errorSongUserCount(error) {
        return { type: lessonConstants.GET_SONG_COUNT_FAILURE, error: error };
    }
  
    function request() {
        return { type: lessonConstants.GET_SONG_COUNT_PENDING };
    }
  
    function success(data) {
        return { type: lessonConstants.GET_SONG_COUNT_SUCCESS, payload: data };
    }
}

function getSearch(params, headers) {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/search`);
    for (let key in params) {
        url.searchParams.append(key, params[key]);
    }

    return (dispatch) => {
        dispatch(request());
        lessonService
        .getLessonsData(url, headers)
        .then((response) => {
            const searchData = {searchValue: response?.data?.data, searchPagination: response?.data?.pagination}
            dispatch(success(searchData));
        })
        .catch((error) => {
            dispatch(errorLesson(error));
        });
    };
  
    function errorLesson(error) {
        return { type: lessonConstants.GET_SEARCH_FAILURE, error: error };
    }
  
    function request() {
        return { type: lessonConstants.GET_SEARCH_PENDING };
    }
  
    function success(data) {
        return { type: lessonConstants.GET_SEARCH_SUCCESS, payload: data };
    }
}

function recentSearches(headers) {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/recent-searches`);

    return (dispatch) => {
        dispatch(request());
        lessonService
        .getLessonsData(url, headers)
        .then((response) => {
            dispatch(success(response?.data?.data));
        })
        .catch((error) => {
            dispatch(errorRecentSearches(error));
        });
    };
  
    function errorRecentSearches(error) {
        return { type: lessonConstants.GET_RECENT_SEARCH_FAILURE, error: error };
    }
  
    function request() {
        return { type: lessonConstants.GET_RECENT_SEARCH_PENDING };
    }
  
    function success(data) {
        return { type: lessonConstants.GET_RECENT_SEARCH_SUCCESS, payload: data };
    }
}

function savedSearches(params, headers) {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/save-searched-requests`);
    for (let key in params) {
        url.searchParams.append(key, params[key]);
    }

    return (dispatch) => {
        dispatch(request());
        lessonService
        .getLessonsData(url, headers)
        .then((response) => {
            dispatch(success(response?.data?.data));
        })
        .catch((error) => {
            dispatch(errorRecentSearches(error));
        });
    };
  
    function errorRecentSearches(error) {
        return { type: lessonConstants.GET_SAVED_SEARCH_FAILURE, error: error };
    }
  
    function request() {
        return { type: lessonConstants.GET_SAVED_SEARCH_PENDING };
    }
  
    function success(data) {
        return { type: lessonConstants.GET_SAVED_SEARCH_SUCCESS, payload: data };
    }
}

function getOtherData(params, headers) {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/search`);
    for (let key in params) {
        url.searchParams.append(key, params[key]);
    }

    return (dispatch) => {
        dispatch(request());
        lessonService
        .getLessonsData(url, headers)
        .then((response) => {
            dispatch(success(response?.data?.data));
        })
        .catch((error) => {
            dispatch(errorLesson(error));
        });
    };
  
    function errorLesson(error) {
        return { type: lessonConstants.GET_OTHER_SEARCH_FAILURE, error: error };
    }
  
    function request() {
        return { type: lessonConstants.GET_OTHER_SEARCH_PENDING };
    }
  
    function success(data) {
        return { type: lessonConstants.GET_OTHER_SEARCH_SUCCESS, payload: data };
    }
}

function getRecentWatchedChapter(params, headers, flag='', isPersonalization = '', lessonId = '') {
    // let url = isPersonalization === 'course' ? lessonId ?  `/api/get-personalization/${params}?child=${lessonId}` : `/api/get-personalization/${params}` : `/api/recentwatched/${params}`;

    return (dispatch) => {
        dispatch(request(flag));
        lessonService
        .getLessonsData(`/api/recentwatched/${params}`, headers)
        .then((response) => {
            dispatch(success(response?.data?.data, flag));
        })
        .catch((error) => {
            dispatch(errorKnowMore(error, flag));
        });
    };
  
    function errorKnowMore(error, flag) {
        return { type: lessonConstants.GET_RECENT_WATCH_DATA_FAILURE, error: {error, flag } };
    }
  
    function request(flag) {
        return { type: lessonConstants.GET_RECENT_WATCH_DATA_PENDING, payload: { flag } };
    }
  
    function success(data, flag) {
        return { type: lessonConstants.GET_RECENT_WATCH_DATA_SUCCESS, payload: { data, flag } };
    }
}