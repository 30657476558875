import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoginSocialGoogle } from "reactjs-social-login";
import { GoogleLoginButton } from "react-social-login-buttons";
import { Link } from "react-router-dom";

import { FormInput } from "../../../../Utility/FormInput";
import { loginSchema } from "../../../../ValidationSchema/LoginSchema";
import { auth } from "../../../../Action/auth.actions";
import { authConstants } from "../../../../Constants/Auth";
import Button from "../../../../Utility/Button";
import '../../../../components/Common/CSS/common.css'

import { authService } from "../../../../Adapters/auth.services";

import { Modal, Button as ReactBtn } from 'react-bootstrap';
import CustomSuccessToast from "../../../../components/CustomToaster/CustomSuccessToaster";
import { toast } from "react-toastify";
import useWindowDimensions from "../../../../Helpers/useWindowDimensions";


const schema = loginSchema;
const CourseSignInWhatsapp = ({ showWhatsAppModal, handleCloseWhatsappModal ,handleSwitchSignIn ,setShowWhatsAppOtpModal ,handleSwitchForm, setShowWhatsAppRegisterModal }) => {

    const { windowWidth } = useWindowDimensions();
    const { whatsAppotpSending, whatsAppotpVerifying, whatsAppOtpSentResponse } = useSelector((state) => state.auth)

    const { id } = useParams()
    const pathname = useLocation()
    const navigate = useNavigate()
    const authState = useSelector((state) => state.auth);
    const { socialToken, token } = authState?.userDetails;
    const countryCode = useSelector((state) => state.auth.countryCode);
    const [country, setCountry] = useState("");
    const [phoneCode, setPhoneCode] = useState('91');
    const [phone, setPhone] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [show, setShow] = useState(false);

    //useStates
    const [passwordLength, setPasswordLength] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onSubmit",
    });
    useEffect(() => {

        if(showWhatsAppModal){
            dispatch(auth.getCountryCode());
        }
    }, [showWhatsAppModal])

    useEffect(() => {
        if (countryCode?.data?.length > 0) {
            window.$('#single-select-field').select2({
                theme: "bootstrap-5",
                width: window.$(this).data('width') ? window.$(this).data('width') : window.$(this).hasClass('w-100') ? '100%' : 'style',
                placeholder: window.$(this).data('placeholder'),
            });

            window.$("#single-select-field").on("change", function (e) {
                setPhoneCode(e.target.value);
            });

        }
    }, [countryCode])
 

const handleModalChange=()=>{
    setShowWhatsAppOtpModal(true)
    handleCloseWhatsappModal()
}

    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        setPhoneCode(selectedValue);
    };

    const onSubmit = (e) => {
        e.stopPropagation()
        if (!isFormValid) {
            showErrors()
            return
        }
        const formData = new FormData();
        formData.append("code", phoneCode);
        formData.append("mobile", phone);

        localStorage.removeItem('remainingTime');
        localStorage.removeItem('whatsappNo');
        localStorage.setItem('whatsappNo', phone)
        localStorage.setItem('phonecode', phoneCode)

        dispatch(auth.sendWhatsappOtp(formData , null, handleModalChange ));


    }


    const handleNavigate=()=>{
        handleSwitchSignIn()
        handleCloseWhatsappModal()

    }

    const handleRegister=()=>{
        handleCloseWhatsappModal()
        setShowWhatsAppRegisterModal(true)

    }

    


    //social login work here
    const handleSocialButton = async (data) => {
        dispatch({ type: authConstants.SOCIAL_LOGIN_REQUEST });
        await authService
            .sendRequest('/api/social-login', data)
            .then(response => {
                if (response?.status == 200) {
                    dispatch({ type: authConstants.SOCIAL_LOGIN_SUCCESS, payload: response.data });
                    if (response?.data?.new_user) {
                        navigate(`${pathname?.pathname}?new_conversion`);
                    } else if (response?.data?.new_user === false) {
                        navigate(`${pathname?.pathname}`)
                    }
                }

            })
            .catch((error) => {
                return dispatch({ type: authConstants.SOCIAL_LOGIN_FAILURE, error: error });
            }).finally(() => {
      
            });


    };



    useEffect(() => {
        setMessage(authState?.userDetails?.message)
    }, [authState])




    useEffect(() => {
        if (phone?.trim()?.length > 0) {
            setShow(true)
        } else {
            setShow(false);
        }

    }, [phone])

    const removeHintText = (field, e) => {

        if (field === "phone") {
            setPhoneError(false)
        }


    }

    const showErrors = () => {
        if (!isNaN(phone.trim()) && phone?.trim()?.length > 6 && phone?.trim()?.length < 16) {
            setPhoneError(false)
        } else {
            setPhoneError(true)
        }
    }
    const showHintText = (field) => {

        if (field === "phone") {
            if (phoneError) {
                return <p className="error-msg d-flex gap-2 align-items-center mt-2">
                    <img className="mr-2" src="/assets/img/error-red.svg" alt="" />
                    Phone number should be between 7 and 15 digits.
                </p>
            }
        }


    }
    const checkFormValid = () => {
        showErrors()
        if (!isNaN(phone.trim()) && phone?.trim()?.length > 6 && phone?.trim()?.length < 16) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false);
        }
    }

    const updatePhoneNO = (e) => {
        setPhone(e.target.value.replace(/\D+/g, ""));
        checkFormValid();
    }
    const countryOptions = countryCode?.data?.map((country) => ({
        phoneCode: country?.phonecode,
        label: country?.name,
        phonecode: country?.phonecode,
        sortname: country?.sortname
    }));

    const filteredCountryOptions = countryOptions?.filter(
        (option) =>
            option.phonecode &&
            option.phonecode.toString().length >= 1 &&
            option.phonecode.toString().length <= 3
    );
    return (
        <>
            <Modal
                show={showWhatsAppModal}
                onHide={handleCloseWhatsappModal}
                centered
                size="md"
                animation={false}
                className="modal signup-modal fans-registermodal whatsapp-login-page whatsappModal "
            >
                <Modal.Body>
                    <div className="d-flex align-items-center justify-content-between">
                        <ReactBtn
                            variant="link"
                            onClick={handleCloseWhatsappModal}
                            className="close"
                            style={{ background: 'none', border: 'none', textAlign: 'end' }}
                        >
                            <img src="/assets/img/cross.svg" alt="Close" />
                        </ReactBtn>
                    </div>
                    <div className="modal-body">
                        <div className="signup-modal-area">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 p-0">
                                    <div className="form-area">
                                        <div className="title" style={{textAlign:'center'}}>
                                        <img src='/assets/img/Login/whatsapp.svg' alt='' />
                                            <h4>Welcome back!</h4>
                                        </div>
                                        <form
                                            onSubmit={handleSubmit(onSubmit)}
                                        >
                                            <div className="form-section" >
                                                <div className="form-group">
                                                    <div>
                                                        <label>Enter your WhatsApp number</label>
                                                        <div className="d-flex gap-2 mt-1">
                                                            <div className="phone-code">
                                                                <select
                                                                    className="form-select"
                                                                    // id="single-select-field"
                                                                    value={phoneCode}
                                                                    onChange={(e) => {
                                                                        handleSelectChange(e);
                                                                    }}
                                                                >
                                                                    <option value="91">+91</option>
                                                                    {filteredCountryOptions?.map((option, index) => (
                                                                        <option key={index} value={option.phoneCode}>
                                                                            {`+${option.phoneCode}`}
                                                                        </option>
                                                                    ))}

                                                                </select>
                                                            </div>
                                                            <input
                                                                className='form'
                                                                id="phoneNumber"
                                                                type="text"
                                                                name="phone"
                                                                placeholder="Enter your whatsapp number"
                                                                value={phone}
                                                                onFocus={(e) => removeHintText("phone", e)}
                                                                onChange={(e) => {
                                                                    updatePhoneNO(e)
                                                                }}

                                                                autoComplete="off"

                                                            />

                                                        </div>
                                                        {showHintText('phone')}


                                                    </div>
                                                </div>

                                                <div className="form-check">
                                                    <FormInput
                                                        className='form-check-input'
                                                        id="newsletter"
                                                        register={register('is_newsletter')}
                                                        error={errors?.is_newsletter}
                                                        name="is_newsletter"
                                                        type="checkbox"
                                                        identity="is_newsletter"
                                                        autoComplete="off"
                                                    />
                                                    <label className="form-check-label" for="newsletter">
                                                        Remember me
                                                    </label>
                                                </div>

                                                <div className="submit-btn">
                                                    {whatsAppotpSending ? <Button className='btn action-btn' label='Loading...' disabled /> : <Button className='btn action-btn' disabled={!show} label='Continue' onClick={(e)=>{onSubmit(e)}}></Button>}
                                                </div>
                                                <div className="or">Or</div>
                                                <div className='login-contents'>
                                                    <div className="register-with-google">
                                                        <LoginSocialGoogle
                                                            className='google-reg'
                                                            client_id='595434321762-t901vug4d7dq10a1ts2m20pv9p0r9rip.apps.googleusercontent.com'
                                                            access_type='online'
                                                            scope='email profile'
                                                            discoveryDocs='claims supported'
                                                            onLoginStart={() =>   handleCloseWhatsappModal()}
                                                            onResolve={({ data }) => {
                                                                handleSocialButton(data)
                                                            }}
                                                            onReject={(err) => {
                                                                console.log(err)
                                                            }}
                                                        >
                                                            {authState.socialSubmit ? <GoogleLoginButton className='google' text={windowWidth > 610 ? '' : 'Login with Google'} /> : <GoogleLoginButton className='google' text={windowWidth > 610 ? '' : 'Login with Google'} />}
                                                        </LoginSocialGoogle>
                                                    </div>
                                                    <div class="register-with-email " >
                                                        <Link to={'#'} style={{ width: '170px' }} onClick={handleNavigate} >Login with Email</Link>
                                                    </div>
                                                </div>

                                                <div className="login-if-account mt-2">
                                                    Don’t have an account? <Link to={'#'} onClick={handleRegister} >Register</Link>
                                                </div>


                                            </div>


                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CourseSignInWhatsapp;
