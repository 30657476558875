import { ReferralConstants } from '../Constants/ReferralConstants'

const initialState = {
    referralData: {},
    referralDetails:[],
    applicableRewards:[],
    sendingReferral: false,
    getReferralStatus: {
        pending: false,
        success: false,
        error: null,
    },
}

export const ReferralReducer = (state = initialState, action) => {
    switch (action.type) {
        case ReferralConstants.GET_REFERRAL_PENDING:
            return {
                ...state,

            };
            break;

        case ReferralConstants.GET_REFERRAL_SUCCESS:

            return {
                ...state,
                referralDetails:action.payload

            };
            break;

        case ReferralConstants.GET_REFERRAL_FAILURE:
            return {
                ...state,
                referralDetails:[]

            };
            break;


        case ReferralConstants.SEND_REFERRAL_PENDING:
            return {
                ...state,
                getReferralStatus: {
                    pending: true,
                    success: false,
                    error: null,
                },
                sendingReferral: true,
            };

        case ReferralConstants.SEND_REFERRAL_SUCCESS:
            return {
                ...state,
                getReferralStatus: {
                    pending: false,
                    success: true,
                    error: null,
                },
                referralData: action.payload,
                sendingReferral: false,


            };

        case ReferralConstants.SEND_REFERRAL_FAILURE:
            return {
                ...state,
                getReferralStatus: {
                    pending: false,
                    success: false,
                    error: action.error
                },
                sendingReferral: false,

            };

            case ReferralConstants.GET_APPLICABLE_REWARDS_PENDING:
            return {
                ...state,
            };

        case ReferralConstants.GET_APPLICABLE_REWARDS_SUCCESS:
            return {
                ...state,
                applicableRewards: action.payload,


            };
        case ReferralConstants.GET_APPLICABLE_REWARDS_FAILURE:
            return {
                ...state,
                applicableRewards:[]

            };


            case "CLEAR_REFERRAL_SUCCESS":
                return {
                    ...state,
                    referralData: null,
                };

        default:
            return state;
    }
}