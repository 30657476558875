import { instructorConstants } from "../Constants/instructorConstants"

const initialState = { 
    loading: false,
    bioLoading:false,
    error: {},
    instructorData: [],
    instructorDetails: [],
    parentCategory: [],
    activeMenu: {},
    songs: [],
    likeSongs: [],
    likedData:{},
    heart:false,
    song_loading: false,
    totalNumberOfSongs: '',
    instructorLoadMoreData:[]
};

export const instructorReducer = ( state=initialState, action ) => {
    switch(action.type) {
        case instructorConstants.GET_INSTRUCTOR_PENDING:
            return {
                ...state,
                loading: true
            };
            break;

        case instructorConstants.GET_INSTRUCTOR_SUCCESS:
            return {
                ...state,
                loading: false,
                instructorDetails: action.payload,
            };
            break;

        case instructorConstants.GET_INSTRUCTOR_FAILURE:
            return {
                ...state,
                bioLoading: false,
                error: action.error,
                
            };
            break;

        case instructorConstants.GET_INSTRUCTOR_DETAILS_PENDING:
            return {
                ...state,
                bioLoading: true,
                instructorData: {}
            };
            break;

        case instructorConstants.GET_INSTRUCTOR_DETAILS_SUCCESS:
            return {
                ...state,
                bioLoading: false,
                instructorData: action.payload,
                parentCategory: action.payload?.data?.lessons,
                activeCategory: action.payload?.data?.lessons[0]
            };
            break;

        case instructorConstants.GET_INSTRUCTOR_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                instructorData: {}
            };
            break;

        case instructorConstants.GET_INSTRUCTOR_CATEGORY_PENDING:
            return {
                ...state,
                cat_loading: true,
                loading: true,
            };
            break;

        case instructorConstants.GET_INSTRUCTOR_CATEGORY_SUCCESS:
            return {
                ...state,
                cat_loading: false,
                instructorData: action.payload,
                loading: false, 
                subCategory: action.payload?.data?.lessons
            };
            break;

        case instructorConstants.GET_INSTRUCTOR_CATEGORY_FAILURE:
            return {
                ...state,
                cat_loading: false,
                error: action.error,
                loading: false, 
            };
            break;

        case instructorConstants.GET_INSTRUCTOR_SONGS_PENDING:
            return {
                ...state,
                song_loading: true,
                songs: [],
                totalNumberOfSongs: 0,
                // updatedLoadMoreData: []
            };
            break;

        case instructorConstants.GET_INSTRUCTOR_SONGS_SUCCESS:
            let updatedLoadMoreData = [...state.instructorLoadMoreData];

            if (action.payload?.totalSongs < 10) {
                updatedLoadMoreData = action.payload.songs;
            } else {
                updatedLoadMoreData = updatedLoadMoreData.concat(action.payload.songs);
            }
            
            return {
                ...state,
                song_loading: false,
                songs: action.payload?.songs,
                totalNumberOfSongs: action.payload?.totalSongs,
                instructorLoadMoreData: updatedLoadMoreData

            };
            break;

        case instructorConstants.GET_INSTRUCTOR_SONGS_FAILURE:
            return {
                ...state,
                song_loading: false,
                error: action.error,
                songs: [],
                totalNumberOfSongs: '',
                instructorLoadMoreData:[]
            };
            break;

        case 'SET_ACTIVE_MENU':
            return { 
                ...state, 
                activeMenu: action.payload,
                error: {"type" : "active", "message" : action?.payload?.message},
                instructorLoadMoreData: []
            };
            break;

        case 'UPDATE_INSTRUCTOR_LOADMORE_DATA':
            return { 
                ...state,
                instructorLoadMoreData: []
            };
            break;
                
        case instructorConstants.LIKE_SONGS_PENDING:
            return {
                ...state,
                heart:false,
                loading: true,
            };
            break;

        case instructorConstants.LIKE_SONGS_SUCCESS:
            return {
                ...state,
                loading: false,
                heart:true,
                likeSongs: action.payload,
            };
            break;

        case instructorConstants.LIKE_SONGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                heart:false,
                likeSongs: []
            };
            break;

        case instructorConstants.GET_LIKED_PENDING:
            return { 
                ...state, 
            };
            break;
        
        case instructorConstants.GET_LIKED_SUCCESS:
            return { 
                auth: false, 
                likedData: action.payload, 
                error: {}  
            };
            break;
        
        case instructorConstants.GET_LIKED_FAILURE:
            return { 
                ...state, 
                error: {"type" : "liked", "message" : action?.payload?.message} 
            };
            break;

        case instructorConstants.REMOVE_SONG_PENDING:
            return {
                ...state,
                loading: true,
                heart: false       
            };
            break;

        case instructorConstants.REMOVE_SONG_SUCCESS:
            return {
                ...state,
                loading: false,
                likeSongs: action.payload,
                heart: true
            };
            break;

        case instructorConstants.REMOVE_SONG_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                heart: true,
                likeSongs: []
            };
            break;

        default:
            return state;
}
}