import { blogsConstants } from '../Constants/blogsConstant';

const initialState = {
    blogsData: [],
    tagsData: [],
    featuredBlogs: [],
    categories: [],
    childCategories: [],
    subChildCategories: [],
    categoriesTabData: [],
    authorData: [],
    isCategoryLoading: false,
    getBlogsStatus: {
        pending: false,
        success: false,
        error: null
    },
    getTagsStatus: {
        pending: false,
        success: false,
        error: null
    },
    getFeaturedBlogsStatus: {
        pending: false,
        success: false,
        error: null
    },
    getCategoriesStatus: {
        pending: false,
        success: false,
        error: null
    },
    getCategoryTabStatus: {
        pending: false,
        success: false,
        error: null
    },
    getAuthorStatus: {
        pending: false,
        success: false,
        error: null
    },
    isFeaturedDataloading: false,

    getviewsStatus: {
        pending: false,
        success: false,
        error: null
    },
    viewsData: [],
    similarBlogsData: [],
    getSimilarBlogsStatus: {
        pending: false,
        success: false,
        error: null
    },
    recomendedCoursesData: [],
    getRecomendedCourseStatus: {
        pending: false,
        success: false,
        error: null
    },

}

export const blogsReducer = (state = initialState, action) => {
    switch (action.type) {

        case blogsConstants.GET_BLOGS_PENDING:
            return {
                ...state,
                getBlogsStatus: {
                    pending: true,
                    success: false,
                    error: null
                },
            };

        case blogsConstants.GET_BLOGS_SUCCESS:
            return {
                ...state,
                getBlogsStatus: {
                    pending: false,
                    success: true,
                    error: null
                },
                blogsData: action.payload
            };

        case blogsConstants.GET_BLOGS_FAILURE:
            return {
                ...state,
                getBlogsStatus: {
                    pending: false,
                    success: false,
                    error: action.error
                },
                blogsData: []

            };


        case blogsConstants.GET_TAGS_PENDING:
            return {
                ...state,
                getTagsStatus: {
                    pending: true,
                    success: false,
                    error: null
                },
            };

        case blogsConstants.GET_TAGS_SUCCESS:
            return {
                ...state,
                getTagsStatus: {
                    pending: false,
                    success: true,
                    error: null
                },
                tagsData: action.payload
            };

        case blogsConstants.GET_TAGS_FAILURE:
            return {
                ...state,
                getTagsStatus: {
                    pending: false,
                    success: false,
                    error: action.error
                },
                tagsData: []

            };


        case blogsConstants.GET_FEATURED_BLOGS_PENDING:
            return {
                ...state,
                getFeaturedBlogsStatus: {
                    pending: true,
                    success: false,
                    error: null
                },
                isFeaturedDataloading: true

            };

        case blogsConstants.GET_FEATURED_BLOGS_SUCCESS:
            return {
                ...state,
                getFeaturedBlogsStatus: {
                    pending: false,
                    success: true,
                    error: null
                },
                featuredBlogs: action.payload,
                isFeaturedDataloading: false

            };

        case blogsConstants.GET_FEATURED_BLOGS_FAILURE:
            return {
                ...state,
                getFeaturedBlogsStatus: {
                    pending: false,
                    success: false,
                    error: action.error
                },
                isFeaturedDataloading: false,
                featuredBlogs: [],


            };


        case blogsConstants.GET_CATEGORIES_PENDING:
            return {
                ...state,
                getCategoriesStatus: {
                    pending: true,
                    success: false,
                    error: null
                },
            };

        case blogsConstants.GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                getCategoriesStatus: {
                    pending: false,
                    success: true,
                    error: null
                },
                categories: action.payload
            };

        case blogsConstants.GET_CATEGORIES_FAILURE:
            return {
                ...state,
                getCategoriesStatus: {
                    pending: false,
                    success: false,
                    error: action.error
                },
                categories: []

            };


        case blogsConstants.GET_CATEGORY_TABS_PENDING:
            return {
                ...state,
                getCategoryTabStatus: {
                    pending: true,
                    success: false,
                    error: null
                },
                categoriesTabData: [],
                isCategoryLoading: true

            };

        case blogsConstants.GET_CATEGORY_TABS_SUCCESS:
            return {
                ...state,
                getCategoryTabStatus: {
                    pending: false,
                    success: true,
                    error: null
                },
                categoriesTabData: action.payload,
                isCategoryLoading: false
            };

        case blogsConstants.GET_CATEGORY_TABS_FAILURE:
            return {
                ...state,
                getCategoryTabStatus: {
                    pending: false,
                    success: false,
                    error: action.error
                },
                isCategoryLoading: false,
                categoriesTabData: [],

            };


        case blogsConstants.GET_BLOGS_VIEWS_SUCCESS:
            return {
                ...state,
                getviewsStatus: {
                    pending: false,
                    success: true,
                    error: null
                },
                viewsData: action.payload
            };

        case blogsConstants.GET_BLOGS_VIEWS_FAILURE:
            return {
                ...state,
                getviewsStatus: {
                    pending: false,
                    success: false,
                    error: action.error
                },
                viewsData: []
            };

        case blogsConstants.GET_BLOGS_VIEWS_PENDING:
            return {
                ...state,
                getviewsStatus: {
                    pending: true,
                    success: false,
                    error: null
                },

            };


        case blogsConstants.GET_AUTHOR_FAILURE:
            return {
                ...state,
                getAuthorStatus: {
                    pending: false,
                    success: false,
                    error: action.error
                },
            };

        case blogsConstants.GET_AUTHOR_PENDING:
            return {
                ...state,
                getAuthorStatus: {
                    pending: true,
                    success: false,
                    error: null
                },
                authorData: [],

            };

        case blogsConstants.GET_AUTHOR_SUCESS:
            return {
                ...state,
                getAuthorStatus: {
                    pending: false,
                    success: true,
                    error: null
                },
                authorData: action.payload,
            };


        case blogsConstants.GET_SIMILAR_BLOGS_FAILURE:
            return {
                ...state,
                getSimilarBlogsStatus: {
                    pending: false,
                    success: false,
                    error: action.error
                },
                similarBlogsData: [],

            };

        case blogsConstants.GET_SIMILAR_BLOGS_PENDING:
            return {
                ...state,
                getSimilarBlogsStatus: {
                    pending: true,
                    success: false,
                    error: null
                },

            };

        case blogsConstants.GET_SIMILAR_BLOGS_SUCESS:
            return {
                ...state,
                getSimilarBlogsStatus: {
                    pending: false,
                    success: true,
                    error: null
                },
                similarBlogsData: action.payload,
            };


        case blogsConstants.GET_RECOMENDED_COURSES_FAILURE:
            return {
                ...state,
                getRecomendedCourseStatus: {
                    pending: false,
                    success: false,
                    error: action.error
                },
                recomendedCoursesData: [],

            };

        case blogsConstants.GET_RECOMENDED_COURSES_PENDING:
            return {
                ...state,
                getRecomendedCourseStatus: {
                    pending: true,
                    success: false,
                    error: null
                },

            };

        case blogsConstants.GET_RECOMENDED_COURSES_SUCESS:
            return {
                ...state,
                getRecomendedCourseStatus: {
                    pending: false,
                    success: true,
                    error: null
                },
                recomendedCoursesData: action.payload,
            };

        case blogsConstants.GET_CHILD_CATEGORIES_FAILURE:
            return {
                ...state,
                childCategories: [],

            };

        case blogsConstants.GET_CHILD_CATEGORIES_PENDING:
            return {
                ...state,
            };

        case blogsConstants.GET_CHILD_CATEGORIES_SUCCESS:
            return {
                ...state,
                childCategories: action.payload,
            };

        case blogsConstants.GET_SUBCHILD_CATEGORIES_FAILURE:
            return {
                ...state,
                subChildCategories: [],

            };

        case blogsConstants.GET_SUBCHILD_CATEGORIES_PENDING:
            return {
                ...state,
            };
        case blogsConstants.GET_SUBCHILD_CATEGORIES_SUCCESS:
            return {
                ...state,
                subChildCategories: action.payload,
            };

        case 'RESET_BLOGS_DATA':
            return {
                blogsData: [],
                tagsData: [],
                featuredBlogs: [],
                categories: [],
                childCategories: [],
                subChildCategories: [],
                categoriesTabData: [],
            }

        default:
            return state;
    }
};






