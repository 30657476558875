import React from 'react'
import { Link } from 'react-router-dom';

const HowToRegister = ({bobData}) => {

    const steps = [
        {
            step: 1,
            title: "Step 1",
        },
        {
            step: 2,
            title: "Step 2",
        },
        {
            step: 3,
            title: "Step 3",
        }
    ];

    return (
        <div className='narayna-register'>
            <p>How can you register?</p>

            <div className='step-containers'>
                {bobData?.registration_steps?.data?.map((stepInfo , index) => (
                    <div key={index} className="step">
                        <h3>{steps[index]?.title}</h3>
                        <p>{stepInfo}</p>
                    </div>
                ))}
            </div>

            <Link className='register-now'  target='_blank' to={bobData?.registration_link}>Register Now</Link>


        </div>
    )
}

export default HowToRegister