import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { auth } from '../../../../Action/auth.actions'
import { Link } from 'react-router-dom'

const VerifyEmailModal = (props) => {
    const dispatch = useDispatch()
    const authState = useSelector((state) => state.auth)
    const emailSubmitted = useSelector((state) => state.auth.emailSubmitted)

    // const onSubmit = (e) => {
    //     e.preventDefault();
    //     const requestData = {
    //         email:localStorage.getItem('email')
    //     }
    //     dispatch(auth.verifyEmail(requestData))
    // }

    return (
        // <div
        //     className={`modal fade signup-modal`}
        //     id="signupmodal"
        //     tabIndex="-1"
        //     aria-labelledby="signmodalLabel"
        //     aria-hidden="true"
        //     style={{ display: props?.show ? "block" : "none", zIndex: props?.zindex? props?.zindex : 999999999999999999, background: props?.extStyle? props?.extStyle : '' }}
        // >
        //     <div className="modal-dialog modal-dialog-centered modal-md">
        //         <div
        //             className="modal-content singup-image"
        //         >
        //             <button
        //                 type="button"
        //                 className="close"
        //                 data-bs-dismiss="modal"
        //                 aria-label="Close"
        //                 // onClick={handleCloseModal}
        //             >
        //                 <svg
        //                     xmlns="http://www.w3.org/2000/svg"
        //                     width="24"
        //                     height="24"
        //                     viewBox="0 0 24 24"
        //                     fill="none"
        //                 >
        //                     <path
        //                         d="M20 20L4 4M20 4L4 20"
        //                         stroke="black"
        //                         stroke-linecap="round"
        //                         stroke-linejoin="round"
        //                     />
        //                 </svg>
        //             </button>
        //             <div className="modal-body">
        //                 <div className="signup-modal-area">
        //                     <div className="row">
        //                         <div className="col-lg-12 col-md-12 p-0">
        //                             <div class="form-area verify-email">
        //                                 <img src="/assets/img/verify.svg" alt="" class="icon" />
        //                                 <h2>Verify your email address</h2>
        //                                 <p class="verify-instruction-msg">We have sent a verification link to your 
        //                                     email address <br />
        //                                 <b>{localStorage.getItem('email')}</b>  
        //                                 </p>
        //                                 <div class="verify-by">
        //                                     <p>To open your email click on your email provider</p>
        //                                     <div>
        //                                         <a href={process.env.REACT_APP_EMAIL_URL} class="google-reg"><img src="/assets/img/google 1.svg" alt="" /></a>
        //                                         <a href={process.env.REACT_APP_OUTLOOK_URL} class="google-reg"><img src="/assets/img/outlook.svg" alt="" /></a>
        //                                         <a href={process.env.REACT_APP_YAHOO_URL} class="google-reg"><img src="/assets/img/yahoo.svg" alt="" /></a>
        //                                     </div>
        //                                 </div>
        //                                 <div class="login-if-account">
        //                                     Did not receive verification link?{emailSubmitted ?<a href="javascript:void(0)" >Resend Link</a> : <a href="javascript:void(0)" onClick={(e) => onSubmit(e)}>Resend Link</a>}
        //                                 </div>
                                        
        //                             </div>
        //                         </div>
        //                         {/* <div className="col-lg-6 col-md-6 p-0">
        //                             <div className="img-area">
        //                                 <img src="" alt="" />
        //                             </div>
        //                         </div> */}
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
               
        //     </div>
        // </div>

        <div class="form-area verify-email">
            <img src="/assets/img/verify.svg" alt="" class="icon" />
            <h2>Verify your email address</h2>
            <p class="verify-instruction-msg">We have sent a verification link to your 
                email address <br />
            <b>{localStorage.getItem('email') || props?.email}</b>  
            </p>
            <div class="verify-by">
                <p>To open your email click on your email provider</p>
                <div>
                    <a href={process.env.REACT_APP_EMAIL_URL} class="google-reg" target='_blank'><img src="/assets/img/google 1.svg" alt="" /></a>
                    <a href={process.env.REACT_APP_OUTLOOK_URL} class="google-reg" target='_blank'><img src="/assets/img/outlook.svg" alt="" /></a>
                    <a href={process.env.REACT_APP_YAHOO_URL} class="google-reg" target='_blank'><img src="/assets/img/yahoo.svg" alt="" /></a>
                </div>
            </div>
            <div class="login-if-account">
                Did not receive verification link?{emailSubmitted ?<Link to="#" >Sending...</Link> : <Link href="#" onClick={(e) => props?.handleResendEmail(e)}>Resend Link</Link>}
            </div>
            
        </div>
        
    )
}

export default VerifyEmailModal
