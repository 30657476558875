export const notesConstants = {
    GET_NOTES_PENDING: "GET_NOTES_PENDING",
    GET_NOTES_SUCCESS: "GET_NOTES_SUCCESS",
    GET_NOTES_FAILURE: "GET_NOTES_FAILURE",

    GET_DELETE_PENDING: "GET_DELETE_PENDING",
    GET_DELETE_SUCCESS: "GET_DELETE_SUCCESS",
    GET_DELETE_FAILURE: "GET_DELETE_FAILURE",

    ADD_NOTES_PENDING: "ADD_NOTES_PENDING",
    ADD_NOTES_SUCCESS: "ADD_NOTES_SUCCESS",
    ADD_NOTES_FAILURE: "ADD_NOTES_FAILURE",

    EDIT_NOTES_PENDING: "EDIT_NOTES_PENDING",
    EDIT_NOTES_SUCCESS: "EDIT_NOTES_SUCCESS",
    EDIT_NOTES_FAILURE: "EDIT_NOTES_FAILURE",

    GET_SAVED_NOTES_PENDING: "GET_SAVED_NOTES_PENDING",
    GET_SAVED_NOTES_SUCCESS: "GET_SAVED_NOTES_SUCCESS",
    GET_SAVED_NOTES_FAILURE: "GET_SAVED_NOTES_FAILURE"
}