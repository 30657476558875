export const WebinarConstants = {
    
    GET_WEBINAR_FAILURE:"GET_WEBINAR_FAILURE",
    GET_WEBINAR_SUCCESS: "GET_WEBINAR_SUCCESS",
    GET_WEBINAR_PENDING: "GET_WEBINAR_PENDING",

    SAVE_INTENT_PENDING:"SAVE_INTENT_PENDING",
    SAVE_INTENT_SUCCESS:"SAVE_INTENT_SUCCESS",
    SAVE_INTENT_FAILURE:"SAVE_INTENT_FAILURE",

    GET_TESTINOMIAL_PENDING:"GET_TESTINOMIAL_PENDING",
    GET_TESTINOMIAL_SUCCESS:"GET_TESTINOMIAL_SUCCESS",
    GET_TESTINOMIAL_FAILURE:"GET_TESTINOMIAL_FAILURE"

    }