import { post, get } from "./xhr";

export const membershipService = {
    getMembershipData,
    sendRequest,
    getAvailablePayment,
    getInstruments,
    sendTokenInHeader,
    getPaymentDetails
  
};

function getMembershipData(url, headers) {
  return get(url, headers);
}

function sendRequest(url, params) {
  return post(url, params);
}

function sendTokenInHeader(url, params, headers) {
  return post(url, params, headers);
}

function getAvailablePayment(url, params, headers) {
  return get(url, params, headers);
}

function getInstruments(url, params) {
  return get(url, params);
}

function getPaymentDetails(url,headers) {
  return get(url, headers);
}


