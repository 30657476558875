import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../Utility/Button';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../../../Action/auth.actions';
import { json, useNavigate } from 'react-router-dom';
import { authConstants } from '../../../Constants/Auth';


const Otpinput = ({ phoneCode, phone, isFromRegistration, isModal, handleCloseWhatsappOtpModal, setShowCreateAccountModal , from='other' }) => {
    const authState = useSelector((state) => state.auth);
    const { whatsAppotpVerifying, whatsAppOtpSentResponse } = useSelector((state) => state.auth)
    const token = authState?.userDetails?.hash
    const newUser = authState?.userDetails?.is_new
    const inputRef = useRef(null);



    const whatsappSentResponse = JSON.parse(localStorage.getItem('whatsappSentResponse'))


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [otp1, setOtp1] = useState("");
    const [otp2, setOtp2] = useState("");
    const [otp3, setOtp3] = useState("");
    const [otp4, setOtp4] = useState("");

    const savedTime = localStorage.getItem('remainingTime');
    const initialTime = savedTime ? parseInt(savedTime, 10) : 120;
    const [seconds, setSeconds] = useState(initialTime);




    useEffect(() => {

        if (inputRef.current) {
            inputRef.current.focus();
        }
        
    }, [ inputRef.current]);

    useEffect(() => {
        if (seconds > 0) {
            const timer = setInterval(() => {
                setSeconds(prevSeconds => {
                    const newSeconds = prevSeconds - 1;
                    localStorage.setItem('remainingTime', newSeconds);
                    return newSeconds;
                });
            }, 1000);

            return () => clearInterval(timer);
        } else {
            localStorage.removeItem('remainingTime'); // Clear the time when the countdown ends
        }
    }, [seconds]);

    const formatTime = (time) => {
        return time < 10 ? `0${time}` : time;
    };
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;


    const [otpError, setOtpError] = useState(false)
    const handleChange = (setter) => (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) {
            setter(value);

        }
        setOtpError(false)
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        const otpValues = { otp1, otp2, otp3, otp4 };
        const allOtpFilled = Object.values(otpValues).every(value => value);

        if (!allOtpFilled) {
            setOtpError(true);
            return;
        } else {
            setOtpError(false);
        }

        const formData = new FormData();
        formData.append("otp", otp1 + otp2 + otp3 + otp4);
        formData.append("requestId", whatsAppOtpSentResponse?.requestId || whatsappSentResponse?.requestId,);
        formData.append("token", whatsAppOtpSentResponse?.token || whatsappSentResponse?.token,);
        dispatch({ type: authConstants.VERIFY_WHATSAPP_OTP_PENDING });
        dispatch(auth.VerifyWhatsappOtp(formData, !isModal && navigate));
    };

    const resendOtp = () => {

        setSeconds(120)


        const formData = new FormData();
        formData.append("code", phoneCode);
        formData.append("mobile", phone);

        dispatch(auth.sendWhatsappOtp(formData));
    }
    const inputfocus = (event) => {
        const currentElement = event.target;

        // Ignore the "Enter" key
        if (event.key === "Enter") {
            event.preventDefault();
            return;
        }

        if (event.key === "Delete" || event.key === "Backspace") {
            const prevIndex = currentElement.tabIndex - 2;
            if (prevIndex > -1) {
                const prevElement = currentElement.form.elements[prevIndex];
                // Move focus to the previous element only if it has a value
                if (prevElement.value) {
                    prevElement.focus();
                }
            }
        } else {
            const nextIndex = currentElement.tabIndex;
            const prevElement = currentElement.form.elements[nextIndex - 1];

            // Only move to the next element if the current element has a value (and the key is not Backspace)
            if (currentElement.value && nextIndex < 4) {
                currentElement.form.elements[nextIndex].focus();
            }
        }
    };


    useEffect(() => {
        if (token) {
            dispatch(auth.getEditProfile({ token: token }))

        }

        if (!isModal) {
          if(from === 'profile'){

          }else{
            dispatch({ type: authConstants.RESET_FLAGS });
            if (newUser) {
                if (isFromRegistration) {
                    navigate("/signup-whatsapp?verified=true");
                } else {
                    navigate("/signin-whatsapp?verified=true");
                }
            } else if ((!authState?.userDetails?.membership_details?.membership && token || newUser === false)) {
                navigate("/guitar-lessons");
            }
            else if (token || newUser === false && authState?.userDetails?.membership_details?.membership) {
                navigate("/dashboard");
            }
          }
        } else if(isModal) {
            if (newUser) {
                setShowCreateAccountModal(true)
                handleCloseWhatsappOtpModal()
            } else if(token && !newUser) {
                handleCloseWhatsappOtpModal()
                let planId = localStorage.getItem('membershipId');
                if (planId){
                    navigate(`/membership/${planId}`)
                }
    
                localStorage.removeItem('membershipId');
            }
         
        }


    }, [token, newUser]);





    return (
        <form >
            <div className="otpContainer">
                <input
                    name="otp1"
                    type="text"
                    autoComplete="off"
                    className="otpInput"
                    value={otp1}
                    onChange={handleChange(setOtp1)}
                    tabIndex="1"
                    maxLength="1"
                    onKeyUp={inputfocus}
                    pattern="\d*"
                    inputMode="numeric"
                    max={1}
                    ref={inputRef} 

                />
                <input
                    name="otp2"
                    type="text"
                    autoComplete="off"
                    className="otpInput"
                    value={otp2}
                    onChange={handleChange(setOtp2)}
                    tabIndex="2"
                    maxLength="1"
                    onKeyUp={inputfocus}
                    pattern="\d*"
                    inputMode="numeric"
                    max={1}
                />
                <input
                    name="otp3"
                    type="text"
                    autoComplete="off"
                    className="otpInput"
                    value={otp3}
                    onChange={handleChange(setOtp3)}
                    tabIndex="3"
                    maxLength="1"
                    onKeyUp={inputfocus}
                    pattern="\d*"
                    inputMode="numeric"
                    max={1}

                />
                <input
                    name="otp4"
                    type="text"
                    autoComplete="off"
                    className="otpInput"
                    value={otp4}
                    onChange={handleChange(setOtp4)}
                    tabIndex="4"
                    maxLength="1"
                    onKeyUp={inputfocus}
                    pattern="\d*"
                    inputMode="numeric"
                    max={1}

                />
            </div>
            {otpError && <p className="error-msg d-flex gap-2 align-items-center mt-2 justify-content-center">
                <img className="mr-2" src="/assets/img/error-red.svg" alt="" />
                Enter OTP
            </p>}
            <div type="submit" className="submit-btn">
                {whatsAppotpVerifying ? <Button className='btn action-btn' label='Loading...' disabled /> : <Button className='btn action-btn' onClick={handleSubmit} label='Verify' />}
            </div>
            <button
                className='btn resend-btn'
                onClick={resendOtp}
                disabled={seconds !== 0}
            >
                Resend {seconds !== 0 ? `(${formatTime(minutes)}:${formatTime(remainingSeconds)})` : ""}
            </button>
        </form>
    );
};

export default Otpinput;
