const CovertDurationFormat = (durationString) => {
    if (durationString === "00:00:00" || durationString == undefined) {
        return "";
    }

    const [hours, minutes, seconds] = durationString?.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;

    if (totalMinutes === 0) {
        return "0 Hours";
    } else {
        if (totalMinutes < 60) {
            return totalMinutes + " Minutes";
        } else {
            const hoursDecimal = (totalMinutes / 60).toFixed(1);
            const hoursString = hoursDecimal.replace(".0", "");
            return hoursString === "1" ? "1 Hour" : hoursString + " Hours";
        }
    }
};

export default CovertDurationFormat;
