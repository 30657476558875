export const bandsConstants = {
    GET_BANDS_FAQ_PENDING: "GET_BANDS_FAQ_PENDING",
    GET_BANDS_FAQ_SUCCESS: "GET_BANDS_FAQ_SUCCESS",
    GET_BANDS_FAQ_FAILURE: "GET_BANDS_FAQ_FAILURE",

    GET_BANDS_INFO_PENDING: "GET_BANDS_INFO_PENDING",
    GET_BANDS_INFO_SUCCESS: "GET_BANDS_INFO_SUCCESS",
    GET_BANDS_INFO_FAILURE: "GET_BANDS_INFO_FAILURE",


    GET_EVENTS_PENDING: "GET_EVENTS_PENDING",
    GET_EVENTS_SUCCESS: "GET_EVENTS_SUCCESS",
    GET_EVENTS_FAILURE: "GET_EVENTS_FAILURE",

    GET_ZONES_PENDING: "GET_ZONES_PENDING",
    GET_ZONES_SUCCESS: "GET_ZONES_SUCCESS",
    GET_ZONES_FAILURE: "GET_ZONES_FAILURE",

    GET_YEARSIMAGES_PENDING: "GET_YEARSIMAGES_PENDING",
    GET_YEARSIMAGES_SUCCESS: "GET_YEARSIMAGES_SUCCESS",
    GET_YEARSIMAGES_FAILURE: "GET_YEARSIMAGES_FAILURE",

    GET_VIDEOURL_PENDING : "GET_VIDEOURL_PENDING",
    GET_VIDEOURL_SUCCESS : "GET_VIDEOURL_SUCCESS",
    GET_VIDEOURL_FAILURE : "GET_VIDEOURL_FAILURE",

    GET_FANPAGE_DATA_PENDING : "GET_FANPAGE_DATA_PENDING",
    GET_FANPAGE_DATA_SUCCESS : "GET_FANPAGE_DATA_SUCCESS",
    GET_FANPAGE_DATA_FAILURE : "GET_FANPAGE_DATA_FAILURE",


    GET_HALL_OF_FAMEDATA_PENDING : "GET_HALL_OF_FAMEDATA_PENDING",
    GET_HALL_OF_FAMEDATA_SUCCESS : "GET_HALL_OF_FAMEDATA_SUCCESS",
    GET_HALL_OF_FAMEDATA_FAILURE : "GET_HALL_OF_FAMEDATA_FAILURE",


    GET_HALL_OF_FAMEDATA_PENDING : "GET_HALL_OF_FAMEDATA_PENDING",
    GET_HALL_OF_FAMEDATA_SUCCESS : "GET_HALL_OF_FAMEDATA_SUCCESS",
    GET_HALL_OF_FAMEDATA_FAILURE : "GET_HALL_OF_FAMEDATA_FAILURE",

    FOLLOW_SUCESS:"FOLLOW_SUCESS",
    FOLLOW_REQUEST:'FOLLOW_REQUEST',
    FOLLOW_FAILURE:'FOLLOW_FAILURE',

    GET_FOLLOWER_FAILURE:"GET_FOLLOWER_FAILURE",
    GET_FOLLOWER_PENDING:'GET_FOLLOWER_PENDING',
    GET_FOLLOWER_SUCESS:"GET_FOLLOWER_SUCESS",

    HOF_MESSAGE_FAILURE:"HOF_MESSAGE_FAILURE",
    HOF_MESSAGE_PENDING:"HOF_MESSAGE_PENDING",
    HOF_MESSAGE_SUCCESS:'HOF_MESSAGE_SUCCESS'

    

}

