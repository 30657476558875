import { combineReducers } from "redux";
import { authReducer } from "./auth.reducer";
import { membershipReducer } from "./membership.reducer";
import { lessonSongsReducer } from "./lessonSongs.reducer";
import { instructorReducer } from "./instructor.reducer";
import { songsReducer } from "./songs.reducer";
import { faqReducer } from "./Faq.reducer";
import { notesReducers } from "./note.reducer";
import { feedbackReducer } from "./feedback.reducer";
import { savedLoopReducer } from "./loop.reducer";
import { assessmentReducer } from "./assessment.reducer";
import { dashboardReducer } from "./User/dashboard.reducer";
import { personalizationReducer } from "./personalization.reducer";
import { homepageReducer } from "./homepage.reducer";
import { seoReducers } from "./seo.reducer";
import { blogsReducer } from "./blogs.reducer";
import { ReferralReducer } from "./referral.reducer";
import { chordsReducer } from "./chords.reducer";
import { bandsReducer } from "./bands.reducer";
import { QaReducer } from "./QA.reducer";
import { webinarReducer } from "./webinar.reducer";

export const menuReducer = ( state = { activeMenu: {} }, action ) => {
   switch(action.type) {
      case 'SET_ACTIVE_MENU': 
         return {
            activeMenu: action.payload
      }
     break;
     
     default:
            return state;
            break;
   }

}

const rootReducer=combineReducers({
   auth: authReducer,
   member: membershipReducer,
   menu: menuReducer,
   lesson: lessonSongsReducer,
   instructor: instructorReducer,
   songs: songsReducer,
   faq: faqReducer,
   note: notesReducers,
   feedback: feedbackReducer,
   saveLoop: savedLoopReducer,
   assesment: assessmentReducer,
   dashboard:dashboardReducer,
   personalization:personalizationReducer,
   homepage:homepageReducer,
   seo:seoReducers,
   blogs:blogsReducer,
   referral:ReferralReducer,
   chords:chordsReducer,
   bands:bandsReducer,
   Qa:QaReducer,
   webinar:webinarReducer
})

export default rootReducer;