import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';

const InstructorsPremium = ({ instructors }) => {
    return (
        <div className="instructors">
            <h6>Learn from our experts</h6>
            <div className="cards">
                <div className="arrow">
                    <div className="arr left left-arrow">
                        <img src='/assets/img/songs/left-arrow.svg' alt='left' />
                    </div>
                </div>
                <Swiper
                    modules={[Navigation]}
                    slidesPerView={1}
                    spaceBetween={10}
                    grabCursor={true}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={{
                        nextEl: ".right-arrow",
                        prevEl: ".left-arrow",
                    }}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        480: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                        },
                        640: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                        940: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                        1200: {
                            slidesPerView: 5,
                            spaceBetween: 40,
                        },
                        1366: {
                            slidesPerView: 6,
                            spaceBetween: 40,
                        },
                    }}
                    className="instructor-slider overflow-hidden"
                >
                    {instructors?.slice(0, 14)?.map((instructor, index) => (
                        <SwiperSlide key={index} className='card-in swiper-wrappe'>
                            <div className="user swiper-slide">
                                <div className="prof">
                                    <Link to={`/instructors/${instructor?.hash}`}>
                                        <img
                                            loading='lazy'
                                            src={instructor?.poster}
                                            alt="inst"
                                        />
                                    </Link>
                                </div>
                                <Link to={`/instructors/${instructor?.hash}`}>
                                    <p>{instructor?.name}</p>
                                </Link>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="arrow">
                    <div className="arr right right-arrow">
                        <img src='/assets/img/songs/right-arrow.svg' alt='right' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InstructorsPremium;
