import React, { useState, useEffect, useRef } from 'react'
import './Webinar.scss'

import useWindowDimensions from '../../Helpers/useWindowDimensions';
import { useDispatch, useSelector } from 'react-redux';
import { webinarAction } from '../../Action/webinar.action';
import LearnWebinarSection from './LearnWebinarSection';
import JoinThisWebinarSection from './JoinThisWebinarSection';
import { loop } from '../../Action/savedLoop.actions';
import { homepageAction } from '../../Action/homepage.action';
import { Helmet } from "react-helmet";
import HelmetComponent from '../../Utility/HelmetComponent';

const Webinar = () => {
    const dispatch = useDispatch()
    const [settings, setSettings] = useState({});
    const { windowWidth } = useWindowDimensions();
    const { activeUsersCount } = useSelector((state) => state.homepage)
    const webinarData = useSelector((state) => state.webinar.webinarData)
    const testinomialData = useSelector((state) => state.webinar.testinomialData)
    const webinarLoading = useSelector((state) => state.webinar.webinarLoading)
    const formRef = useRef(null);
    const nameInputRef = useRef(null);

    const handleJoinClick = () => {
        if (formRef.current) {
            // Scroll to the form
            formRef.current.scrollIntoView({ behavior: "smooth" });

            // Use a timeout to ensure the scroll completes before focusing
            setTimeout(() => {
                if (nameInputRef.current) {
                    nameInputRef.current.focus(); // Focus on the name input
                }
            }, 300); // Adjust the timeout as needed
        }
    };


    useEffect(() => {
        const isMobileView = windowWidth <= 781;
        dispatch(webinarAction.getWebinar())
        if (isMobileView) {
            setSettings({
                dots: true,
                arrow: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow: false,
                nextArrow: false,
                autoplay: true,
                loop: true,
                responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                ]
            })
        }
        dispatch({ type: 'SET_SEO_VALUE', payload: { type: 'WEBINAR', id: '0' } })
        dispatch(homepageAction.fetchHomepageData());
        dispatch(webinarAction.getTestinomialData());
    }, []);

    return (
        <>
            <HelmetComponent />
            {webinarLoading ? (
                <div className="loading-container" style={{minHeight:'400px', margin:'50px'}}>
                    <div
                        className="loader spinner-border m-5 d-table m-auto"
                        role="status"
                    >
                        <span className="visually-hidden"></span>
                    </div>
                    <span className=" m-5 d-table m-auto">Loading...</span>
                </div>
            ) : (
                <div className='webinar-page'>
                    <LearnWebinarSection
                        webinarData={webinarData}
                        handleJoinClick={handleJoinClick}
                        nameInputRef={nameInputRef}
                        formRef={formRef}
                        activeUsersCount={activeUsersCount}
                    />
                    <JoinThisWebinarSection
                        settings={settings}
                        handleJoinClick={handleJoinClick}
                        testinomialData={testinomialData}
                    />
                </div>)}
        </>
    )
}

export default Webinar
