export const ReferralConstants = {
  GET_REFERRAL_PENDING: "GET_REFERRAL_PENDING",
  GET_REFERRAL_SUCCESS: "GET_REFERRAL_SUCCESS",
  GET_REFERRAL_FAILURE: "GET_REFERRAL_FAILURE",

  SEND_REFERRAL_PENDING: "SEND_REFERRAL_PENDING",
  SEND_REFERRAL_SUCCESS: "SEND_REFERRAL_SUCCESS",
  SEND_REFERRAL_FAILURE: "SEND_REFERRAL_FAILURE",

  GET_APPLICABLE_REWARDS_PENDING: "GET_APPLICABLE_REWARDS_PENDING",
  GET_APPLICABLE_REWARDS_SUCCESS: "GET_APPLICABLE_REWARDS_SUCCESS",
  GET_APPLICABLE_REWARDS_FAILURE: "GET_APPLICABLE_REWARDS_FAILURE",
}