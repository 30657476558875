export const membershipConstants = {
    GET_MEMBERSHIP_FAILURE: "GET_MEMBERSHIP_FAILURE",
    GET_MEMBERSHIP_PENDING: "GET_MEMBERSHIP_PENDING",
    GET_MEMBERSHIP_SUCCESS: "GET_MEMBERSHIP_SUCCESS",

    COUPON_CODE_SUCCESS: "COUPON_CODE_SUCCESS",
    COUPON_CODE_PENDING: "COUPON_CODE_PENDING",
    COUPON_CODE_FAILURE: "COUPON_CODE_FAILURE",

    GET_AVAILABLE_PAYMENT_SUCCESS: "GET_AVAILABLE_PAYMENT_SUCCESS",
    GET_AVAILABLE_PAYMENT_REQUEST: "GET_AVAILABLE_PAYMENT_REQUEST",
    GET_AVAILABLE_PAYMENT_FAILURE: "GET_AVAILABLE_PAYMENT_FAILURE",

    GET_INSTRUMENT_SUCCESS: "GET_INSTRUMENT_SUCCESS",
    GET_INSTRUMENT_REQUEST: "GET_INSTRUMENT_REQUEST",
    GET_INSTRUMENT_FAILURE: "GET_INSTRUMENT_FAILURE",

    CHECKOUT_PENDING: "CHECKOUT_PENDING",
    CHECKOUT_SUCCESS: "CHECKOUT_SUCCESS",
    CHECKOUT_FAILURE: "CHECKOUT_FAILURE",

    GET_PAYMENT_DETAILS_PENDING: "GET_PAYMENT_DETAILS_PENDING",
    GET_PAYMENT_DETAILS_SUCCESS: "GET_PAYMENT_DETAILS_SUCCESSE",
    GET_PAYMENT_DETAILS_FAILURE: "GET_PAYMENT_DETAILS_FAILURE",

    CANCEL_SUBSCRIPTION_PENDING: "CANCEL_SUBSCRIPTION_PENDING",
    CANCEL_SUBSCRIPTION_SUCCESS: "CANCEL_SUBSCRIPTION_SUCCESS",
    CANCEL_SUBSCRIPTION_FAILURE: "CANCEL_SUBSCRIPTION_FAILURE",

    GET_AVAILABLE_COUPON_PENDING: "GET_AVAILABLE_COUPON_PENDING",
    GET_AVAILABLE_COUPON_SUCCESS: "GET_AVAILABLE_COUPON_SUCCESS",
    GET_AVAILABLE_COUPON_FAILURE: "GET_AVAILABLE_COUPON_FAILURE",

    RESET_COUPAN_DATA: 'RESET_COUPAN_DATA',

    GET_GIFTCARD_TEMPLATES_PENDING: "GET_GIFTCARD_TEMPLATES_PENDING",
    GET_GIFTCARD_TEMPLATES_SUCCESS: "GET_GIFTCARD_TEMPLATES_SUCCESS",
    GET_GIFTCARD_TEMPLATES_FAILURE: "GET_GIFTCARD_TEMPLATES_FAILURE"


}