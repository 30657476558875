export const blogsConstants = {
  GET_BLOGS_PENDING: "GET_BLOG_PENDING",
  GET_BLOGS_SUCCESS: "GET_BLOG_SUCCESS",
  GET_BLOGS_FAILURE: "GET_BLOG_FAILURE",

  GET_TAGS_PENDING: 'GET_TAGS_PENDING',
  GET_TAGS_SUCCESS: 'GET_TAGS_SUCCESS',
  GET_TAGS_FAILURE: 'GET_TAGS_FAILURE',

  GET_FEATURED_BLOGS_PENDING: 'GET_FEATURED_BLOGS_PENDING',
  GET_FEATURED_BLOGS_SUCCESS: 'GET_FEATURED_BLOGS_SUCCESS',
  GET_FEATURED_BLOGS_FAILURE: 'GET_FEATURED_BLOGS_FAILURE',

  GET_CATEGORIES_PENDING: 'GET_CATEGORIES_PENDING',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAILURE: 'GET_CATEGORIES_FAILURE',

  GET_CATEGORY_TABS_PENDING: 'GET_CATEGORY_TABS_PENDING',
  GET_CATEGORY_TABS_SUCCESS: 'GET_CATEGORY_TABS_SUCCESS',
  GET_CATEGORY_TABS_FAILURE: 'GET_CATEGORY_TABS_FAILURE',

  ADD_BLOGS_VIEWS_PENDING: 'ADD_BLOGS_VIEWS_PENDING',
  ADD_BLOGS_VIEWS_SUCCESS: 'ADD_BLOGS_VIEWS_SUCCESS',
  ADD_BLOGS_VIEWS_FAILURE: 'ADD_BLOGS_VIEWS_FAILURE',

  GET_BLOGS_VIEWS_PENDING: 'GET_BLOGS_VIEWS_PENDING',
  GET_BLOGS_VIEWS_SUCCESS: 'GET_BLOGS_VIEWS_SUCCESS',
  GET_BLOGS_VIEWS_FAILURE: 'GET_BLOGS_VIEWS_FAILURE',


  GET_AUTHOR_PENDING: 'GET_AUTHOR_PENDING',
  GET_AUTHOR_SUCESS: 'GET_AUTHOR_SUCESS',
  GET_AUTHOR_FAILURE: 'GET_AUTHOR_FAILURE',


  GET_SIMILAR_BLOGS_PENDING: 'GET_SIMILAR_BLOGS_PENDING',
  GET_SIMILAR_BLOGS_SUCESS: 'GET_SIMILAR_BLOGS_SUCESS',
  GET_SIMILAR_BLOGS_FAILURE: 'GET_SIMILAR_BLOGS_FAILURE',


  GET_RECOMENDED_COURSES_FAILURE:'GET_RECOMENDED_COURSES_FAILURE',
  GET_RECOMENDED_COURSES_SUCESS:'GET_RECOMENDED_COURSES_SUCESS',
  GET_RECOMENDED_COURSES_PENDING:'GET_RECOMENDED_COURSES_PENDING',


  GET_CHILD_CATEGORIES_PENDING: 'GET_CHILD_CATEGORIES_PENDING',
  GET_CHILD_CATEGORIES_SUCCESS: 'GET_CHILD_CATEGORIES_SUCCESS',
  GET_CHILD_CATEGORIES_FAILURE: 'GET_CHILD_CATEGORIES_FAILURE',

  GET_SUBCHILD_CATEGORIES_FAILURE: 'GET_SUBCHILD_CATEGORIES_FAILURE',
  GET_SUBCHILD_CATEGORIES_PENDING:'GET_SUBCHILD_CATEGORIES_PENDING',
  GET_SUBCHILD_CATEGORIES_SUCCESS :'GET_SUBCHILD_CATEGORIES_SUCCESS'




};
